import text from '../text';
import type { GetReturnTypes, ReturnTypes } from '../types/api';
import { getItem, setItem } from './LocalStorageContext';

export const endpoints = {
  post: {
    inspections: '/api/save-exam'
  },
  get: {
    logIn: '/api/login',
    logOut: '/api/logout',
    currentUser: '/api/current-user',
    tubeTypes: '/api/tubeType',
    protectiveMeasures: '/api/protective-measures',
    activities: '/api/activities',
    defects: '/api/defects',
    examTypes: '/api/examtype',
    companies: '/api/companies',
    locations: '/api/location/##secondKey##', // company
    systems: '/api/system/##secondKey##', // location
    tubes: '/api/tubes/##secondKey##', // system
    exams: '/api/get-all-exam-check-deleted'
  },
  link: {
    addCompany: '/tester-add-value/company',
    addLocation: '/tester-add-value/location/##secondKey##', // company-id
    addSystem: '/tester-add-value/system/##secondKey##' // location-id
  }
};

type Method = keyof typeof endpoints;
type EndpointKeys<M extends Method> = keyof (typeof endpoints)[M];
type SpecificEndpointKey<M extends Method> = M extends keyof typeof endpoints
  ? EndpointKeys<M>
  : never;

export type SpecificReturnType<
  M extends Method,
  K extends SpecificEndpointKey<M>
> = K extends keyof ReturnTypes ? ReturnTypes[K] : never;

/**
 * Loads data from the backend.
 * @returns
 */
export async function loadData<GetEndpointKey extends SpecificEndpointKey<'get'>>(
  key: GetEndpointKey,
  secondKey = ''
): Promise<GetReturnTypes[GetEndpointKey][GetEndpointKey]> {
  let url = getUrlForEndpoint('get', key, secondKey);

  if (!navigator.onLine) {
    const data = await getItem(url);
    return cleanData(key, data[key]);
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });

    if (key === 'logIn') {
      const text = (await response.text()) as SpecificReturnType<'get', GetEndpointKey>;
      return text;
    } else {
      // I am ashamed. may later generations do better!
      if (!response.status) {
        // Todo: do some nice useHistory stuff here
        window.location.href = '/login';
      }
      const data = (await response.json()) as SpecificReturnType<'get', GetEndpointKey>;

      setItem(url, data);

      return cleanData<'get', typeof key>(key, data[key]); // data[key];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

/**
 * Gets the url for a specific endpoint.
 * @returns
 */
export function getUrlForEndpoint<M extends Method>(
  method: M,
  endpointKey: SpecificEndpointKey<M>,
  secondKey?: string
) {
  // TODO: Don't use "as". It overrides the type system.
  let apiUri = endpoints[method][endpointKey] as string;

  // console.log({ apiUri });

  if (secondKey) {
    apiUri = apiUri.replaceAll(`##secondKey##`, secondKey);
  }
  return getBaseUrl() + apiUri;
}

// Extract the element type of an array, or use the original type if it's not an array
type ElementType<T> = T extends Array<infer E> ? E : T;

/**
 * @returns
 */
function cleanData<M extends Method, K extends SpecificEndpointKey<M>>(
  key: K,
  data: ReturnTypes[K]
) {
  if (Array.isArray(data)) {
    return data.map((d: ElementType<ReturnTypes[K]>) => {
      return cleanSingleData(key, d);
    });
  }
  return cleanSingleData(key, data);
}

/**
 * Cleans a single data object.
 * @returns
 */
function cleanSingleData<
  M extends Method,
  Key extends SpecificEndpointKey<M>,
  Data extends ElementType<ReturnTypes[Key]> | ReturnTypes[Key]
>(key: Key, data: Data) {
  switch (key) {
    case 'defects':
      return { name: data.defect, ...data };
    case 'actions':
      return { name: data.action, ...data };
    case 'locations':
      return { name: data.location, ...data };
    case 'companies':
      return { name: data.company, ...data };
    case 'examTypes':
      return { name: data.exam_type, ...data };
    case 'tubeTypes':
      return { name: data.tube_types, ...data };
    case 'activities':
      return { name: data.actions, ...data };
    case 'protective-measures':
      return { name: data.protective_measures, ...data };
    case 'tubes':
      return { name: data.tubes, ...data };
    case 'systems':
      return { name: data.systems, ...data };
    default:
      return data;
  }
}

/**
 * Pushes data to the backend.
 * @returns
 */
export async function pushData<K extends SpecificEndpointKey<'post'>>(
  key: K,
  data: SpecificReturnType<'post', K>
) {
  let url = getUrlForEndpoint('post', key);

  let response;
  try {
    response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  } catch (error) {
    console.error(error);
    throw text.errors.corruptedNetwork;
  }

  if (response.status === 200) {
    return response.json() as Promise<SpecificReturnType<'post', K>>;
  }

  if (response.status === 500) {
    const data = await response.json();
    throw data.errorMessage;
  }
  throw text.errors.corruptedData;
}

/**
 * Gets the base url for the backend.
 */
export function getBaseUrl() {
  return `${process.env.REACT_APP_BACKEND_BASE_ENDPOINT}`;
}
