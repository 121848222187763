import React, { useContext, useEffect, useState } from 'react';
import Login from '../components/Login/Login';
import { CurrentUser } from '../types/responses';
import { loadData } from './ApiHandler';

const AuthContext = React.createContext<{
  currentUser: ExtendedCurrentUser | undefined;
} | null>(null);

export function useAuth() {
  return useContext(AuthContext);
}

type Props = {
  children: any;
};

type ExtendedCurrentUser = CurrentUser & { permitted: boolean };

export function AuthProvider({ children }: Props) {
  const [currentUser, setCurrentUser] = useState<ExtendedCurrentUser>();
  const [loading, setLoading] = useState(true);

  const value = {
    currentUser
  };

  useEffect(() => {
    const loadCurrentUser = async () => {
      let currentUser = (await loadData('currentUser')) as ExtendedCurrentUser;

      if (currentUser && currentUser.eMail) {
        currentUser.permitted = true;
      } else {
        // @ts-ignore
        currentUser = { permitted: false };
      }
      setCurrentUser(currentUser);
      setLoading(false);
    };
    loadCurrentUser();
  }, []);

  if (!loading && !currentUser?.permitted) {
    return <Login />;
  }

  if (!loading && currentUser?.permitted) {
    return (
      <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
    );
  }

  return '';
}
