import React from 'react';
import { Global, css } from '@emotion/react';

const ThemeStyles = () => {
  return (
    <Global
      styles={css`
        * {
          margin: 0;
        }
        :root {
          --primary-color: #005387;
          --primary-color-hover: #3071a9;
          --secondary-color: #00a0a2;
          --secondary-color-hover: #00a0a2;
          --third-color: #e38b0c;
          --third-color-hover: #d4830e;
          --text-color: #000000;
          --dark-color: #000000;
          --dark-gray-color: #595959;
          --light-gray-color: #595959;
          --white-color: #fff;
          --white-color-form: #f9f9f9;
          --primary-gradient: linear-gradient(
            90deg,
            rgba(0, 83, 135, 1) 0%,
            rgba(0, 160, 162, 1) 100%
          );
          --secondary-gradient: linear-gradient(89.07deg, #00a0a2 0.68%, #005387 101.43%);
          --error-color: rgba(217, 72, 72, 1);
          --transition: all 500ms ease-in-out;

          body {
            font-family: 'Gudea';
          }

          .logo {
            background-image: url('./logo.png');
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: 80%;
            width: 200px;
            height: 200px;
            margin: 0 auto;
          }

          .btn {
            background: var(--primary-color);
            outline: none;
            border: none;
            color: var(--white-color);
            transition: var(--transition);
            padding: 12px;
          }

          .btn:hover {
            background: var(--secondary-color);
          }

          .btn-primary {
            background: var(--third-color);
          }

          .btn-primary:hover {
            background: var(--third-color-hover);
          }

          .btn-transparent {
            background: transparent;
            color: var(--light-gray-color);
            outline: none;
            border: none;
          }

          textarea:focus,
          input:focus,
          input[type]:focus,
          .uneditable-input:focus,
          .css-yk16xz-control:focus {
            border-color: var(--primary-color);
            box-shadow: 0 0 1px var(--secondary-color) inset, 0 0 2px var(--primary-color);
            outline: 0 none;
          }
        }
      `}
    />
  );
};

export default ThemeStyles;
