import React from 'react';
import { Form } from 'react-bootstrap';

function InputGroup(props: any) {
  let datalist = <></>;
  const listId = `${props.controlId}-datalist`;
  if (props.datalist) {

    datalist = (
      <datalist id={listId}>
        {props.datalist.map((optProp: string, index: number) => (
          <option key={index} value={optProp}>{optProp}</option>
        ))}
      </datalist>
    );
  }

  return (
    <>
      <Form.Group controlId={props.controlId}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control list={listId} {...props} autocomplete={'off'} />
        {datalist}
      </Form.Group>
    </>
  );
}

export default InputGroup;
