import React from 'react';
import { Container } from 'react-bootstrap';
import DashboardView from '../../components/DashboardView/DashboardView';

const Dashboard = () => {
  return (
    <Container className="mt-4 mb-4">
      <DashboardView />
    </Container>
  );
};

export default Dashboard;
