import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { loadData } from '../../contexts/ApiHandler';

const Logout = () => {
  const history = useHistory();
  useEffect(() => {
    const doLogout = async () => {
      try {
        await loadData('logOut');
      } finally {
        history.push('/login');
      }
    };
    doLogout();
  }, [history]);

  return '';
};

export default Logout;
