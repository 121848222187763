import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';

const LoadingButton: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Button className="ml-3" disabled>
      <Spinner size="sm" animation="border" role="status" as="span" aria-hidden="true" />{' '}
      &nbsp; {text}
    </Button>
  );
};

LoadingButton.propTypes = {
  text: PropTypes.string.isRequired
};

export default LoadingButton;
