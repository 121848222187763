import React, { useContext, useEffect, useState } from 'react';
import SelectTube from './SelectTube';
import inspectionContext from '../../../contexts/InspectionContext';
import AddTube from './AddTube';

const CarriedOutInspectionTab = () => {
  const [newTube, setNewTube] = useState(false);
  let [allTubes, setAllTubes] = useState([]);
  let [createdTube, setCreatedTube] = useState();
  let { inspection } = useContext(inspectionContext);
  const [allNewTubes, setAllNewTubes] = useState(inspection.newTubes || []);

  useEffect(() => {
    if (inspection.exams) {
      setAllTubes(inspection.exams);
    }
  }, [inspection.exams]);

  if (!newTube)
    return (
      <SelectTube
        allTubes={allTubes}
        setAllTubes={setAllTubes}
        setNewTube={setNewTube}
        createdTube={createdTube}
        allNewTubes={allNewTubes}
      />
    );

  return (
    <AddTube
      setCreatedTube={setCreatedTube}
      setNewTube={setNewTube}
      allNewTubes={allNewTubes}
      setAllNewTubes={setAllNewTubes}
    />
  );
};

export default CarriedOutInspectionTab;
