import React, { useState, useEffect, useContext, useCallback } from 'react';
import Select from '../../../partials/WsSelect';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { loadData } from '../../../contexts/ApiHandler';
import text from '../../../text';
import inspectionContext from '../../../contexts/InspectionContext';
import { getCurrentDateAmerican } from '../../../contexts/LocalStorageContext';
import LoadingScreen from '../../../views/LoadingScreen/LoadingScreen';
import moment from 'moment';

export default function EditConstruction({
  allTubes,
  setAllTubes,
  activeTubeId,
  isChanging
}) {
  const [defects, setDefects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [userName, setUserName] = useState('');
  const [protectiveMeasures, setProtectiveMeasures] = useState([]);
  let { getTubeById, setTubeById } = useContext(inspectionContext);

  useEffect(() => {
    const fetchData = async () => {
      let defects = await loadData('defects');
      defects = defects.map((d) => {
        return { value: d.id, label: d.name };
      });
      setDefects(defects);

      let activities = await loadData('activities');
      activities = activities.map((activity) => {
        return { value: activity.id, label: activity.activity };
      });
      setActivities(activities);

      let protectiveMeasures = await loadData('protectiveMeasures');
      protectiveMeasures = protectiveMeasures.map((pMeasure) => {
        return { value: pMeasure.id, label: pMeasure.measure };
      });

      setProtectiveMeasures(protectiveMeasures);

      let user = await loadData('currentUser');
      setUserName(user.fullName);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  // Access current tube

  console.log('activeTubeId', activeTubeId)
  const currentTube = getTubeById(activeTubeId);

  const getConstructionYear = useCallback((constructionYear) => {
    const cYear = moment(constructionYear, 'DD.MM.YYYY');

    if (!cYear.isValid()) {
      return constructionYear
    }

    return cYear.format('MM.YYYY')
  }, []);

  // Function for changing tube

  const updateTube = (key, event) => {
    if (!event && event !== false) return;
    const value = event.target ? event.target.value : event;
    currentTube[key] = value;

    setTubeById(activeTubeId, currentTube);

    // Update allTubes array
    allTubes = allTubes.map((tube) => {
      if (tube.fid === currentTube.fid) {
        return currentTube;
      }
      return tube;
    });

    setAllTubes([...allTubes]);
  };

  const onChangeResult = (event) => {
    updateTube('result', event.target.value);
  };

  const onChangeBuildUp = (event) => {
    updateTube('removed', !!event.target.checked); 
  };

  const handleSelectValueChange = (e, key) => {
    let valueList = [];

    if (!e) {
      currentTube[key] = undefined;
      updateTube(key, undefined);
      return;
    }

    if (!e.value) {
      valueList = e.map((option) => option.value);
      currentTube[key] = valueList;
      updateTube(key, valueList);
      return;
    }

    currentTube[key] = e.value;
    updateTube(key, e.value);
  };

  if (isLoading || isChanging) {
    return <LoadingScreen small />;
  }

  return (
 
    <div className="input-section">
      {currentTube && ( <span style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}><strong>{currentTube.name}</strong></span> )}
     
      <Form.Group controlId="date">
        <Form.Label>{text.InspectionCard.carriedOutInspection.testDate}</Form.Label>
        <Form.Control
          defaultValue={currentTube.date ? currentTube.date : getCurrentDateAmerican()}
          onChange={(e) => updateTube('date', e)}
          type="date"
        />
      </Form.Group>

      {/* This part shows the EXAMINER */}
      <Form.Group controlId="user">
        <Form.Label>{text.InspectionCard.carriedOutInspection.examiner}</Form.Label>
        <Form.Control readOnly defaultValue={userName} type="text" />
      </Form.Group>

      <Form.Group controlId="construction_year">
        <Form.Label>
          {text.InspectionCard.carriedOutInspection.construction_year}
        </Form.Label>
        <Form.Control readOnly defaultValue={getConstructionYear(currentTube.construction_year)} type="text" />
      </Form.Group>

      {/* This part shows the PROTECTIVE MEASURES  */}
      <Form.Group className="column" controlId="ProtectiveMeasures">
        <Form.Label>
          {text.InspectionCard.carriedOutInspection.protectiveMeasures}
        </Form.Label>
        <Select
          defaultValue={protectiveMeasures.filter((o) =>
            currentTube.protective_measures.includes(o.value)
          )}
          onChange={(e) => handleSelectValueChange(e, 'protective_measures')}
          isMulti
          options={protectiveMeasures}
          isSearchable={false}
        />
      </Form.Group>

      {/* This part shows the DEFECTS  */}
      <Form.Group className="column" controlId="defects">
        <Form.Label>{text.InspectionCard.carriedOutInspection.defects}</Form.Label>
        <Select
          defaultValue={defects.filter((o) => currentTube.defects.includes(o.value))}
          onChange={(e) => handleSelectValueChange(e, 'defects')}
          id="defects"
          options={defects}
          isMulti={true}
        />
      </Form.Group>

      {/* This part shows the ACTIVITIES - rename to action?  */}
      <Form.Group className="column" controlId="action">
        <Form.Label>{text.InspectionCard.carriedOutInspection.activities}</Form.Label>
        <Select
            defaultValue={
              Array.isArray(currentTube.activities)
                  ? activities.filter((o) => currentTube.activities.includes(o.value))
                  : []
            }
            onChange={(e) => handleSelectValueChange(e, 'activities')}
            id="action"
            options={activities}
            isClearable
            isSearchable={false}
            isMulti={true}
        />
      </Form.Group>


      <Form.Group className="column" controlId="tubeLocation">
        <Form.Label>{text.InspectionCard.carriedOutInspection.tubeLocation}</Form.Label>
        <Form.Control
          style={{ width: '100%' }}
          onChange={(e) => updateTube('tubeLocation', e)}
          defaultValue={currentTube.tubeLocation}
          type="text"
        />
      </Form.Group>

      {/* RESULT BUTTONS  */}
      <div className="radioPills-container radio-result">
        <div>{text.InspectionCard.carriedOutInspection.result}</div>
        <div onChange={onChangeResult} className="radioPills radioColumn">
          <input
            className="radio-input"
            type="radio"
            name="result"
            value="0"
            id="yes"
            required
            defaultChecked={`${currentTube.result}` === '0'}
          />
          <label className="radio-label radio-label__good" htmlFor="yes">
            {text.InspectionCard.carriedOutInspection.good}
          </label>
          <input
            className="radio-input"
            type="radio"
            name="result"
            id="hint"
            value="1"
            defaultChecked={`${currentTube.result}` === '1'}
          />
          <label className="radio-label radio-label__hint" htmlFor="hint">
            {text.InspectionCard.carriedOutInspection.tip}
          </label>
          <input
            className="radio-input"
            type="radio"
            name="result"
            id="no"
            value="2"
            defaultChecked={`${currentTube.result}` === '2'}
          />
          <label className="radio-label radio-label__bad" htmlFor="no">
            {text.InspectionCard.carriedOutInspection.notGood}
          </label>
        </div>
      </div>

      <div className="radioPills-container">
        <div>{text.InspectionCard.carriedOutInspection.more}</div>
        <div className="radio-buttons">
          <div className="radioPills">
            <input
              onClick={onChangeBuildUp}
              defaultChecked={currentTube.removed}
              className="radio-input"
              type="checkbox"
              name="buildUp"
              id="buildUp"
            />
            <label className="radio-label radio-label__bad" htmlFor="buildUp">
              {text.InspectionCard.carriedOutInspection.buildUp}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

EditConstruction.propTypes = {
  setNewTube: PropTypes.func,
  setAllTubes: PropTypes.func,
  allTubes: PropTypes.array,
  activeTubeId: PropTypes.number,
  isChanging: PropTypes.bool
};
