import React, { useContext } from 'react';
import Logo from '../Logo';
import { Link } from 'react-router-dom';
import text from '../../text';
import inspectionContext from '../../contexts/InspectionContext';

const InspectionHead = () => {
  const { company, system, location } = useContext(inspectionContext);

  return (
    <header className="header">
      <Logo size="small" />
      <div className="header-text">
        <Link className="btn" to="/dashboard">
          {text.SubmitScreen.toDashboard}
        </Link>
        <div className="header-info">
          <div>
            <strong>{text.InspectionCard.header.customer}</strong>: {company.name}
          </div>
          <div>
            <strong>{text.InspectionCard.header.system}</strong>: {system.name}
          </div>
          <div>
            <strong>{text.InspectionCard.header.location}</strong>: {location.name}
          </div>
        </div>
      </div>
    </header>
  );
};

export default InspectionHead;
