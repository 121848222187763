import React, { useContext, useEffect, useState } from 'react';
import Select from '../../../partials/WsSelect';
import { loadData } from '../../../contexts/ApiHandler';
import { Form, Col } from 'react-bootstrap';
import text from '../../../text';
import inspectionContext from '../../../contexts/InspectionContext';
import LoadingScreen from '../../../views/LoadingScreen/LoadingScreen';

const InspectionInfoTab = () => {
  const operatingMode = ['Mehrschichtbetrieb', 'Einschichtbetrieb'];
  const [checkType, setCheckType] = useState([]);
  const [defaultValue, setDefaultValue] = useState({});
  const testInterval = [6, 12, 18, 32];
  const { system, inspection, setInspection } = useContext(inspectionContext);

  useEffect(() => {
    const populateData = async () => {
      const checkTypeRaw = await loadData('examTypes');
      const checkType = checkTypeRaw.map((type) => {
        return { value: type.id, label: type.name };
      });

      defaultValue.examType = checkType.find(
        (data) => data.value === inspection.examtype
      );
      if (!inspection.testBasis) {
        inspection.testBasis = text.InspectionCard.inspectionInfo.testBasisMessage;
      }

      defaultValue.loaded = true;
      setDefaultValue(defaultValue);
      setCheckType(checkType);
    };

    populateData();
  }, [defaultValue, inspection]);

  function handleChange(key, value) {
    inspection[key] = value;
    setInspection(inspection);
  }

  if (checkType.length === 0 || !defaultValue.loaded) {
    return <LoadingScreen small />;
  }

  return (
    <div className="inspectionTab-container mt-3">
      <Col>
        <Form.Group controlId="checkType">
          <Form.Label>{text.InspectionCard.inspectionInfo.checkType}</Form.Label>
          <Select
            defaultValue={defaultValue.examType}
            options={checkType}
            onChange={(option) => handleChange('examtype', option.value)}
          />
        </Form.Group>

        <Form.Group controlId="testBasis">
          <Form.Label>{text.InspectionCard.inspectionInfo.testBasis}</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            defaultValue={inspection.testBasis}
            onChange={(event) => handleChange('testBasis', event.target.value)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="testIntervalMonth">
          <Form.Label>{text.InspectionCard.inspectionInfo.testIntervalMonth}</Form.Label>
          <Form.Control
            type="number"
            min="1"
            defaultValue={inspection.testIntervalMonth}
            onChange={(event) => handleChange('testIntervalMonth', event.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="testIntervalRecommend">
          <Form.Label>
            {text.InspectionCard.inspectionInfo.testIntervalRecommend}{' '}
            {operatingMode[system.operating_mode]}
            :&nbsp;
          </Form.Label>
          <Form.Control
            type="number"
            value={testInterval[system.operating_mode]}
            disabled
          />
        </Form.Group>
      </Col>
    </div>
  );
};

export default InspectionInfoTab;
