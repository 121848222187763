import React from 'react';
import { Container, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import text from '../../text';
import Logo from '../../components/Logo';
import './noPermission.scss';

const NoPermission = () => {
  return (
    <Container className="noPermission-container d-flex flex-column align-items-center justify-content-center">
      <Logo size="large" />
      <Card>
        <Card.Body>
          <div className="mb-4">
            <strong>{text.NoPermission.headline}</strong>
          </div>
          <p>{text.NoPermission.sentenceProblem}</p>
          <p>{text.NoPermission.sentenceAlternative}</p>
          <Link className="btn btn-primary w-50 mx-auto text-light" to="/login/">
            {text.general.backTo} {text.login.submit}
          </Link>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default NoPermission;
