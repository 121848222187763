import React, { useEffect, useState } from 'react';
import { Container, Card } from 'react-bootstrap';
import Logo from '../Logo';
import { loadData, getBaseUrl, endpoints } from '../../contexts/ApiHandler';
import text from '../../text';
import { useHistory } from 'react-router-dom';

type LoginFormType = string | null;

const Login = () => {
  const [loginForm, setLoginForm] = useState<LoginFormType>(null);
  const history = useHistory();

  useEffect(() => {
    const fetchFormData = async () => {
      let currentUser = await loadData('currentUser');

      if (currentUser && currentUser.eMail) {
        history.push('/dashboard');
        return;
      }

      let form: LoginFormType = (await loadData('logIn')) as LoginFormType;

      if (form && typeof form === 'string') {
        form = form
          .replace('##email##', text.general.email)
          .replace('##password##', text.general.password)
          .replace('##forgot-password##', text.login.forgotPassword)
          .replace('##protocol##', text.login.protocol)
          .replace('##management##', text.login.management)
          .replace('##submit##', text.login.submit)
          .replace(
            ' <form method="post">',
            ` <form method="post" action="${getBaseUrl() + endpoints.get.logIn}">`
          );
        setLoginForm(form);
      } else {
        history.push('/no-permission');
      }
    };
    fetchFormData();
  }, [history]);

  return (
    <>
      {loginForm ? (
        <Container className="login-container d-flex flex-column align-items-center justify-content-center">
          <Logo size="small" />
          <Card>
            <Card.Header>
              <h2>{text.login.submit}</h2>
            </Card.Header>
            <Card.Body>
              <>
                <div dangerouslySetInnerHTML={{ __html: loginForm }} />
              </>
            </Card.Body>
          </Card>
        </Container>
      ) : (
        ''
      )}
    </>
  );
};

export default Login;
