import React, { useContext, useEffect } from 'react';
import InspectionHead from '../../components/InspectionHead';
import InspectionCard from '../../components/InspectionCard';
import { Container } from 'react-bootstrap';
import inspectionContext from '../../contexts/InspectionContext';
import { Redirect } from 'react-router';

export default function Inspection() {
  let { inspection, initCurrentInspection } = useContext(inspectionContext);
  const hasInspection = Object.keys(inspection).length > 0;

  useEffect(() => {
    if (!hasInspection)
      return;

    initCurrentInspection();
  }, [hasInspection, initCurrentInspection])

  if (!hasInspection) {
    return <Redirect to="./dashboard" />
  }


  return (
    <Container>
      <InspectionHead></InspectionHead>
      <InspectionCard></InspectionCard>
    </Container>
  );
}
