import React from 'react';
import logo from '../../assets/img/logo.png';
import { Link } from 'react-router-dom';

const sizes = {
  small: {
    width: 130,
    height: 'auto'
  },
  medium: {
    width: 170,
    height: 'auto'
  },
  large: {
    width: 200,
    height: 'auto'
  }
};

const Logo = ({ size }: { size: 'small' | 'medium' | 'large' }) => {
  return (
    <Link to="/">
      <img src={logo} alt="logo" width={sizes[size].width} height={sizes[size].height} />
    </Link>
  );
};

export default Logo;
