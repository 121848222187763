import React from 'react';
import './loadingScreen.scss';
import { Container } from 'react-bootstrap';

const LoadingScreen = ({showText}: {showText?: true}) => {
  //
  // This code has been adapted from https://codepen.io/martinvd/pen/xbQJom
  //
  return (
    <Container className="loading-container loading-container--small">
      <div className="loader">
        <div className="line one"></div>
        <div className="line two"></div>
        <div className="line three"></div>
      </div>
      {showText ?? (

      <p>
        Daten werden geladen. <br />
        Dies kann einen Moment dauern ..
      </p>
      )}
    </Container>
  );
};

export default LoadingScreen;
