import React from 'react';
import { ReactSVG } from 'react-svg';
import trash from '../../assets/icons/trash.svg';
import add from '../../assets/icons/plus.svg';
import circle_red from '../../assets/icons/circle-red.svg';
import circle_green from '../../assets/icons/circle-green.svg';
import circle_orange from '../../assets/icons/circle-orange.svg';
import circle_gray from '../../assets/icons/circle-gray.svg';
import logout from '../../assets/icons/logout.svg';
import admin from '../../assets/icons/admin.svg';
import edit from '../../assets/icons/edit.svg';
import PropTypes from 'prop-types';

const Icon = ({ name, onClick }) => {
  const iconSet = {
    trash,
    add,
    edit,
    circle_red,
    circle_green,
    circle_orange,
    circle_gray,
    admin,
    logout
  };

  return <ReactSVG onClick={onClick} src={iconSet[name]} />;
};

Icon.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icon;
