import React, { useEffect, useState } from 'react';

function renderOnline() {
  return (
    <svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.02 42.98l23.26-28.98c-.9-.68-9.85-8-23.28-8-13.43 0-22.38 7.32-23.28 8l23.26 28.98.02.02.02-.02z" />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
  );
}

function renderOffline() {
  return (
    <svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.02 42.98l23.26-28.98c-.9-.68-9.85-8-23.28-8-13.43 0-22.38 7.32-23.28 8l23.26 28.98.02.02.02-.02z"
        fillOpacity=".3"
      />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
  );
}

export default function Offline({ children }: { children: React.ReactNode }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const checkConnectionMode = async () => {
      window.addEventListener('online', () => setIsOnline(true));
      window.addEventListener('offline', () => setIsOnline(false));
      navigator.onLine ? setIsOnline(true) : setIsOnline(false);
    };
    checkConnectionMode();
  }, []);

  if (children && isOnline) {
    // nothing to show
    return '';
  } else if (children && !isOnline) {
    return children;
  } else if (isOnline) {
    return renderOnline();
  } else {
    return renderOffline();
  }
}