import React, { useEffect, useState } from 'react';
import { ProgressBar, Row } from 'react-bootstrap';
import { endpoints, loadData } from '../../contexts/ApiHandler';
import LoadingScreen from '../../views/LoadingScreen/LoadingScreen';
import PropTypes from 'prop-types';

/**
 * 
 * @param {*} setProgress 
 */
async function preloadData(setProgress) {
  const blacklist = ['logIn', 'logOut'];
  const data = {};
  let progress = 0;
  for (const key of Object.keys(endpoints.get)) {
    data[key] = [];

    if (!endpoints.get[key].includes('##secondKey##')) {
      if (blacklist.includes(key)) {
        continue;
      }

      const result = await loadData(key);
      data[key] = result ?? [];
      setProgress(progress++);
    }
  }

  let key = 'locations';
  for (const comp of data.companies) {
    data[key] = data[key].concat(await loadData(key, comp.id));
  }
  setProgress(progress++);

  key = 'systems';
  for (const location of data.locations) {
    data[key] = data[key].concat(await loadData(key, location.id));
  }
  setProgress(progress++);

  key = 'tubes';
  for (const sys of data.systems) {
    data[key] = data[key].concat(await loadData(key, sys.id));
  }

  setProgress(progress++);
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export function Preloader({ children }) {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const preloadAllData = async () => {
      await preloadData(setProgress);
      setLoading(false);
    };
    preloadAllData();
  }, []);

  if (loading) {
    return (
      <Row>
        <LoadingScreen />
        <ProgressBar max={Object.values(endpoints.get).length} now={progress} />
      </Row>
    );
  }

  return <>{!loading && children}</>;
}

Preloader.propTypes = {
  children: PropTypes.any
};
