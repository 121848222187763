import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import text from '../../text';
import { getBaseUrl } from '../../contexts/ApiHandler';
import Icon from '../Icon/Icon';

const Footer = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  useEffect(() => {
    const checkConnectionMode = async () => {
      window.addEventListener('online', () => setIsOnline(true));
      window.addEventListener('offline', () => setIsOnline(false));
      navigator.onLine ? setIsOnline(true) : setIsOnline(false);
    };
    checkConnectionMode();
  }, []);

  return (
    <footer className="footer-btn-container d-flex align-items-center justify-content-between">
      <span>{text.general.version}</span>
      {isOnline ? (
        <div className="d-flex gap-2 align-items-center">
          {renderSwitchToAdmin()}
          {renderLogout()}
        </div>
      ) : (
        ''
      )}
    </footer>
  );
};

export default Footer;

const renderSwitchToAdmin = () => {
  return (
    <a
      href={getBaseUrl()}
      className="footer-btn"
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      <Icon name="admin" />
      <span> {text.general.admin}</span>
    </a>
  );
};

const renderLogout = () => {
  return (
    <Link to="/logout" className="footer-btn">
      <Icon name="logout" />
      <span>{text.general.logout}</span>
    </Link>
  );
};
