import React, { useContext, useState } from 'react';
import './Debug.scss';

import InspectionContext from '../../contexts/InspectionContext';

export default function Debug() {
  const [isDebugOpen, setIsDebugOpen] = useState(false);
  const { client, location, system, inspection } = useContext(InspectionContext);

  return (
    <div className="debug">
      {isDebugOpen ? (
        <div>
          <button onClick={() => setIsDebugOpen(false)}>Close debug</button>
          <h2>Debug</h2>
          <ul>
            <li>
              <strong>Client (name and id):</strong> {client?.name} {client?.id}
            </li>
            <li>
              <strong>Location (name and id):</strong> {location?.name} {location?.id}
            </li>
            <li>
              <strong>System:</strong> {system?.name} {system?.id} {system?.system_type}
            </li>
          </ul>
          <hr />
          <h2>Inspection</h2>
          <div className="debug__details">{JSON.stringify({ inspection }, null, 2)}</div>
          <hr />
          <h2>New Tubes</h2>
          <div className="debug__details">
            {JSON.stringify(inspection.newTubes, null, 2)}
          </div>
        </div>
      ) : (
        <button onClick={() => setIsDebugOpen(true)}>Open debug</button>
      )}
    </div>
  );
}
