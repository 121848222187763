import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import NoPermissions from '../views/NoPermission';

export default function PrivateRoute({ component, ...params }) {
  const { currentUser } = useAuth();

  // user is not logged in
  if (!currentUser) {
    return <Redirect to="./login" />;
  }

  // user got no permissions for this view
  if (!currentUser.permitted) {
    return <NoPermissions />;
  }

  // user is permitted, render the view
  return <Route {...params} render={component} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.any
};
