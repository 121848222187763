import debugFactory from 'debug';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getBaseUrl } from '../../../contexts/ApiHandler';
import inspectionContext from '../../../contexts/InspectionContext';
import text from '../../../text';
import {
  handleTubeColor,
  mapArrayToObject,
  mapObjectToArray,
  sortAlphabetically,
  sortArraysInObject,
  sortByTubeNumber
} from '../../../utils/functions';
import Icon from '../../Icon/Icon';
import EditConstruction from './EditConstruction';
import TubeDeleteModal from './TubeDeleteModal';

const debug = debugFactory('app:SelectTube');

function renderTubeName(tube) {
  if (tube.tube_number) {
    return (
      <span>
        <strong>{tube.tube_number}</strong>&nbsp;-&nbsp;<span></span>
        {tube.name}
      </span>
    );
  }
  return <span>{tube.name}</span>;
}

const SelectTube = ({ setNewTube, allTubes, setAllTubes, createdTube, allNewTubes }) => {
  const BACKEND_URL = getBaseUrl();
  const [activeTubeId, setActiveTubeId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteTube, setShowDeleteTube] = useState(false);
  const [deleteTube, setDeleteTube] = useState(null);
  let { inspection } = useContext(inspectionContext);

  const sortedTubes = useMemo(() => {
    const sortedTubes = allTubes.sort((current, next) => {
      return sortByTubeNumber(current.tube_number, next.tube_number);
    });
    const sortedObject = mapArrayToObject(sortedTubes, 'tube_number');
    const newArray = sortArraysInObject(sortedObject, (current, next) => {
      return sortAlphabetically(current.name, next.name);
    });
    return mapObjectToArray(newArray);
    // eslint-disable-next-line
  }, [allTubes, deleteTube]);

  const handleChangeSelectTube = (activeTubeId) => {
    setIsLoading(true);
    setTimeout(() => {
      setActiveTubeId(activeTubeId);
      setIsLoading(false);
    }, 500);
  };

  debug('all tubes', allTubes);
  debug('last created tube', createdTube);
  debug('new created tubes', allNewTubes);

  const { deleteTubeFromInspection } = useContext(inspectionContext);

  const openDeleteModal = (e, tube) => {
    e.stopPropagation();

    setDeleteTube(tube);
    setShowDeleteTube(true);
  };

  /**
   * Delete tube from inspection.
   * Update the active tube index.
   */
  const handleDelete = async () => {
    /**
     * The tube to be deleted
     */
    const deleteIndex = allTubes.findIndex((i) => i.fid === deleteTube.fid);

    console.log('deletedTube.fid', deleteTube.fid);

    /**
     * The index of the current active tube
     */
    const activeTubeIndex = allTubes.findIndex((i) => i.fid === activeTubeId);

    console.log(
      'This is the index of the deleted tube and the next active',
      deleteIndex,
      activeTubeIndex
    );

    let nextActiveIndex = activeTubeIndex;

    if (deleteIndex === activeTubeIndex) {
      nextActiveIndex = deleteIndex - 1;
    } else if (deleteIndex < activeTubeIndex) {
      nextActiveIndex = activeTubeIndex - 1;
    }

    const isOutOfRange = (index) => index < 0;
    if (isOutOfRange(nextActiveIndex)) nextActiveIndex = 0;

    await deleteTubeFromInspection(deleteTube);
    setDeleteTube(null);

    console.log({ nextActiveIndex });
    const nextActive = allTubes[nextActiveIndex].fid;

    handleChangeSelectTube(nextActive);
  };

  const addNewTube = () => {
    setNewTube(true);
  };

  useEffect(() => {
    if (!createdTube) return;
    setActiveTubeId(createdTube.fid);
  }, [createdTube, allTubes]);

  return (
    <div className="carriedOut-container mt-3">
      <div className="col-tube-selector">
        <div className="col-tube-headline">
          {text.InspectionCard.carriedOutInspection.selectTube}
        </div>

        <Nav className="flex-column">
          {sortedTubes.map((tube, index) => {
            const isNewTube = allNewTubes.some((newTube) => newTube.fid === tube.fid);
            return (
              <Nav.Link
                key={tube.fid}
                onClick={() => handleChangeSelectTube(tube.fid)}
                active={activeTubeId === tube.fid}
              >
                <div style={{
                  display: 'flex',
                  gap: '10px'
                }}>
                  <Icon name={handleTubeColor(tube.result)} />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      gap: '10px'
                    }}
                  >
                    <span>{index + 1}</span>

                    {renderTubeName(tube)}
                  </div>
                </div>

                {inspection.completed === false ? (
                  <div className="button-box">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          {isNewTube
                            ? text.InspectionCard.carriedOutInspection.notSavedEditTube
                            : text.InspectionCard.carriedOutInspection.editTube}
                        </Tooltip>
                      }
                    >
                      <button
                        className="empty-btn"
                        onClick={(e) => {
                          window.open(`${BACKEND_URL}/tubes/${tube.id}/edit`, '_blank');
                        }}
                        disabled={isNewTube}
                      >
                        {' '}
                        <Icon name="edit" />
                      </button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          {text.InspectionCard.carriedOutInspection.deleteTube}
                        </Tooltip>
                      }
                    >
                      <button
                        className="empty-btn"
                        onClick={(e) => {
                          openDeleteModal(e, tube);
                        }}
                      >
                        <Icon name="trash" />
                      </button>
                    </OverlayTrigger>
                  </div>
                ) : (
                  <div style={{ width: '20px', height: '20px' }}></div>
                )}
              </Nav.Link>
            );
          })}
        </Nav>
        <TubeDeleteModal
          show={showDeleteTube}
          onHide={() => setShowDeleteTube(false)}
          onAccept={handleDelete}
        />

        {inspection.completed === false ? (
          <div className="align-items-end my-3">
            <button onClick={addNewTube} className="btn btn-primary">
              {text.InspectionCard.carriedOutInspection.newTube}
            </button>
          </div>
        ) : null}
      </div>
      {allTubes.length > 0 ? (
        <EditConstruction
          allTubes={allTubes}
          setAllTubes={setAllTubes}
          activeTubeId={activeTubeId}
          isChanging={isLoading}
        />
      ) : (
        <div className="tube-message">
          <p>{text.InspectionCard.carriedOutInspection.notubemessage1}</p>
          <p>{text.InspectionCard.carriedOutInspection.notubemessage2}</p>
        </div>
      )}
    </div>
  );
};

SelectTube.propTypes = {
  setNewTube: PropTypes.func,
  allTubes: PropTypes.array,
  setAllTubes: PropTypes.func,
  createdTube: PropTypes.object,
  allNewTubes: PropTypes.array
};

export default SelectTube;
