import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { tubeBaseStructure } from '../components/InspectionTabs/CarriedOutInspectionTab/AddTube';
import text from '../text';
import { loadData, pushData } from './ApiHandler';
import { updateInspection } from './LocalStorageContext';

const store = {
  client: { name: '', id: 0 },
  location: { name: '', id: 0 },
  system: {
    name: '',
    id: 0,
    // operating_mode: 0,
    system_type: 'Presse Hydraulik'
    // change_interval_manufacturer: 6,
    // change_interval_operator_time: 12,
    // change_interval_operator_text:
    //   'Wurde durch die Verkürzung der Prüfintervalle der DGUV112-020 auf alle 6 Monate und mit den Anlagehersteller abgesprochen.',
    // recom_shift: 'Luis Sanchez',
    // risk_assessment: 'vorhanden',
    // circuit_diagram: 'nicht vorhanden',
    // manual: 'vorhanden'
  },
  company: { name: '', id: 0 },

  /** Inspection is basically the current exam/inspection the user is fulfilling. Find some of its methods in the InspectionContext.js. It will belong later to the store.inspection, which has more information inside, like the company/client, location or system.  */

  inspection: {
    // deletedTubes: [11,12,19],
    // risk_assessment: true,
    // operating_mode: 0,
    // machine_manual: true,
    // circuit_diagram: true,
    // change_interval_operator_time: 6,
    // change_interval_operator_text: '',
    // change_interval_manufacturer: 6,
    // recom_shift: 6,
    // emailSignerCompany: 'test@borsch.de',
    // ------*----- //
    // ------*----- //
    // ------*----- //
    // id: 0,
    // // state: 'nicht unterschrieben',
    // date: '07.03.2021',
    // company: 1,
    // location: 1,
    // system: 1,
    // orderNumber: '134185',
    // examtype: 1,
    // testBasis: '',
    // testIntervalMonth: 12,
    // nameSignerCompany: 'Captain Douglas Blake',
    // signatureTester: 'data:image/png;base64,iVBORw0KGgoA...',
    // signatureCompany: 'data:image/png;base64,iVBORw0KGgo...',
    // anomalies: 'Spongebob-Aufkleber über dem Sicherungskasten',
    // errorCodeWasShown: 1,
    // exams: [
    //   {
    //     tube: 1,
    //     isNew: false,
    //     date: '17.03.2021',
    //     examiner: 'mr. Hurley',
    //     result: 0,
    //     defects: [1, 2, 3, 4],
    //     action: 1,
    //     removed: false,
    //     tank: false,
    //     protectiveMeasures: [1, 2, 3]
    //   },
    //   {
    //     tube: 'ksdvhkjdnv',
    //     isNew: true,
    //     date: '17.03.2021',
    //     examiner: 'mr. Hurley',
    //     result: 0,
    //     defects: [1, 2, 3, 4],
    //     action: 1,
    //     removed: false,
    //     tank: false,
    //     protectiveMeasures: [1, 2, 3]
    //   }
    // ],
    // newTubes: [
    //   {
    //     uid: 'ksdvhkjdnv',
    //     label: '250 06',
    //     buildYear: '01.03.2020',
    //     tubeType: 1
    //   }
    // ]
  },

  setInspection: async (inspection) => {
    store.inspection = inspection;

    await updateInspection(store.inspection);
  },
  setCompany: (company) => {
    store.company = company;
  },
  setLocation: (location) => {
    store.location = location;
  },
  setSystem: (system) => {
    store.system = system;
  },
  addTube: async (tube, isNew = true) => {
    if (isNew) {
      tube.fid = uuidv4();
    }
    if (!store.inspection.exams) {
      store.inspection.exams = [];
    }
    if (!store.inspection.newTubes) {
      store.inspection.newTubes = [];
    }
    store.inspection.exams.push(tube);
    if (isNew) {
      store.inspection.newTubes.push(tube);
    }
    await store.setInspection(store.inspection);
    return tube;
  },
  getTube(index) {
    const t = store.inspection.exams[index];
    const tube = { ...tubeBaseStructure };
    Object.assign(tube, t);
    return tube;
  },
  getTubeById(fid) {
    const t = store.inspection.exams.find((i) => i.fid === fid);
    const tube = { ...tubeBaseStructure };
    Object.assign(tube, t);
    return tube;
  },
  setTubeById: (fid, tube) => {
    const index = store.inspection.exams.findIndex((i) => i.fid === fid);
    store.inspection.exams[index] = tube;

    store.setInspection(store.inspection);
  },
  deleteTubeFromInspection(deleteTube) {
    return store.deleteTubeById(deleteTube.fid);
  },
  async deleteTubeById(fid) {
    // Look through tubes saved by database
    const foundExamIndex = store.inspection.exams.findIndex((i) => i.fid === fid);
    // Look through tubes that haven't been saved yet
    const foundNewExamIndex = store.inspection.newTubes.findIndex((i) => i.fid === fid);

    store.inspection.exams.splice(foundExamIndex, 1);

    if (foundNewExamIndex >= 0) {
      store.inspection.newTubes.splice(foundNewExamIndex, 1);
    } else {
      if (!store.inspection.deletedTubes) {
        store.inspection.deletedTubes = [];
      }

      store.inspection.deletedTubes.push(fid);
    }

    console.log('deletedTubes', store.inspection.deletedTubes);

    // We need this line to update the reference to the array
    // Otherwise the UI won't update in CarriedOutInspectionTab
    store.inspection.exams = [...store.inspection.exams];

    await store.setInspection(store.inspection);
  },
  initCurrentInspection: async () => {
    if (
      store.inspection.signed ||
      store.inspection.completed ||
      store.inspection.synced
    ) {
      return store.inspection;
    }

    const systemTubes = await loadData('tubes', store.system.id);

    for (const t of systemTubes) {
      if (canAddTube(t)) {
        const tube = { ...tubeBaseStructure };
        Object.assign(tube, t);
        store.addTube(tube, false);
      }
    }

    return store.inspection;
  },

  saveInspection: async (
    inspection,
    setErrors,
    setIsLoading,
    setInspection,
    ignoreLocalErrors = false
  ) => {
    let errors = [];
    setIsLoading(true);

    let hasError = false;
    // Validation signatures
    if (
      !inspection.signatureCompany ||
      inspection.signatureCompany === '' ||
      !inspection.signatureTester ||
      inspection.signatureTester === ''
    ) {
      errors.push({ message: text.errors.missingSignature, inspection });
      hasError = true;
    }

    // Validation examTypes

    if (!inspection.examtype || inspection.examtype === '') {
      errors.push({ message: text.errors.missingExamType, inspection });
      hasError = true;
    }

    if (!inspection.testIntervalMonth || inspection.testIntervalMonth === '') {
      errors.push({ message: text.errors.testIntervalMonth, inspection });
      hasError = true;
    }

    // Validation nameSignerEmployer
    if (!inspection.nameSignerEmployer || !inspection.nameSignerEmployer === '') {
      errors.push({ message: text.errors.missingNameSigner, inspection });
      hasError = true;
    }

    if (!ignoreLocalErrors && hasError) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }

    if (inspection.synced) {
      setErrors([{ message: text.InspectionCard.alreadySynced }]);
      setIsLoading(false);
      return;
    }

    if (!navigator.onLine) {
      inspection.synced = false;
      inspection.completed = true;
      setErrors([{ message: text.errors.corruptedNetwork }]);
      await setInspection(inspection);
      return true;
    }

    try {
      const response = await pushData('inspections', inspection);
      inspection.synced = true;
      inspection.completed = true;
      inspection.newExamId = response.newExamId;

      if (response.examDetailLink) {
        inspection.printLink = response.examDetailLink;
      }

      await setInspection(inspection);
      await setIsLoading(false);
      return true;
    } catch (error) {
      setErrors([{ message: translateError(error.toString()) }]);
      setIsLoading(false);
    }
  }
};

function canAddTube(tube) {
  const hasTubes = !!store.inspection.exams;
  const isTubeAlreadyInInspection =
    hasTubes && !!store.inspection.exams.find((e) => e.fid === tube.fid);
  const isTubeDeleted =
    !!store.inspection.deletedTubes &&
    !!store.inspection.deletedTubes.find((tubeId) => tubeId === tube.fid);
  return !isTubeDeleted && (!hasTubes || !isTubeAlreadyInInspection);
}

function translateError(error) {
  if (error.includes('change_interval_operator_time')) {
    return text.errors.changeIntervalOperatorTime;
  }

  if (error.includes('emailSignerEmployer')) {
    return text.errors.emailSignerEmployer;
  }

  if (error.includes('testIntervalMonth')) {
    return text.errors.testIntervalMonth;
  }

  if (error.includes('is not valid')) {
    return error.replace('is not valid', text.errors.isNotValidValue);
  }

  return error;
}

export default React.createContext(store);
