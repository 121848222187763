import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import text from '../../text';
import './submitScreen.scss';

const SubmitBody = () => {
  const [data, setData] = useState([]);
  const [mode, setMode] = useState('online');

  useEffect(() => {
    let userData = '/data.json';
    fetch(userData)
      .then((response) => {
        response.json().then((result) => {
          console.warn(result);
          setData(result);
          localStorage.setItem('inspections', JSON.stringify(result));
        });
      })
      .catch((err) => {
        setMode('offline');
        let dataCollection = localStorage.getItem('inspections');
        setData(JSON.parse(dataCollection));
      });
  }, []);
  return (
    <Card.Body>
      <h2>{text.SubmitScreen.congratulationsMessage}</h2>
      {mode === 'offline' ? (
        <p>{text.SubmitScreen.offlineMessage}</p>
      ) : (
        <p>{text.SubmitScreen.onlineMessage}</p>
      )}
      <div key={data.id}>{data.name}</div>
      <Link className="btn btn-primary mt-3" to="/dashboard" as={Link}>
        {text.SubmitScreen.toDashboard}
      </Link>
    </Card.Body>
  );
};

export default SubmitBody;
