import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import vLogo from '../../assets/icons/check-circle.svg';
import company from '../../assets/icons/company.svg';
import location from '../../assets/icons/location.svg';
import print from '../../assets/icons/print.png';
import system from '../../assets/icons/system.svg';
import xLogo from '../../assets/icons/x-circle.svg';
import { loadData } from '../../contexts/ApiHandler';
import inspectionContext from '../../contexts/InspectionContext';
import { getAllInspection } from '../../contexts/LocalStorageContext';
import Offline from '../../partials/Offline';
import text from '../../text';
import LoadingScreen from '../../views/LoadingScreen/LoadingScreen';
import './dashboardTable.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const sortByDate = ({ inspection: current }, { inspection: next }) => {
  const dateCurrent = current.lastUpdated ? current.lastUpdated : current.date;
  const dateNext = next.lastUpdated ? next.lastUpdated : next.date;

  const currentTs = moment(dateCurrent, 'YYYY.MM.DD hh:mm:ss').unix();
  const nextTs = moment(dateNext, 'YYYY.MM.DD hh:mm:ss').unix();

  if (currentTs < nextTs) return 1;

  if (currentTs > nextTs) return -1;

  return 0;
};

const sortSignedLast = ({ inspection: current }, { inspection: next }) => {
  if (current.signed) return 1;

  if (next.signed) return -1;

  return 0;
};

const sortSyncedLast = ({ inspection: current }, { inspection: next }) => {
  if (current.synced) return 1;

  if (next.synced) return -1;

  return 0;
};

const renderOfflineMessage = () => {
  return (
    <Offline>
      <div className="offline-message">{text.general.offlineMessage}</div>
    </Offline>
  );
};

const renderState = (inspection) => {
  return (
    <ul className="inspection-states">
      <li>
        <span>{text.Dashboard.state.completed}:</span>
        <img src={inspection.completed ? vLogo : xLogo} alt="complete" />
      </li>

      <li>
        <span>{text.Dashboard.state.signed}:</span>
        <img src={inspection.signed ? vLogo : xLogo} alt="signed" />
      </li>
      <li>
        <span>{text.Dashboard.state.synced}:</span>
        <img src={inspection.synced ? vLogo : xLogo} alt="complete" />
      </li>
    </ul>
  );
};

const DashboardTable = () => {
  const history = useHistory();
  const [inspections, setInspections] = useState();
  const [syncDisabled, setSyncDisabled] = useState(false);
  const [isOnline, setOnline] = useState(navigator.onLine);
  const [errors, setErrors] = useState([]);
  let {
    setInspection,
    setCompany,
    setLocation,
    setSystem,
    saveInspection: globalSaveInspection
  } = useContext(inspectionContext);

  const showSyncMessage = () => {
    if (isOnline) {
      return (
        <>
          {text.Dashboard.saveMessage}
          <br />
          <br />
        </>
      );
    }
    return false;
  };

  const renderSync = (inspection) => {
    if (!inspection.synced && inspection.completed) {
      return (
        <button
          className="btn"
          disabled={syncDisabled || !isOnline}
          onClick={() => {
            globalSaveInspection(inspection, setErrors, setSyncDisabled, setInspection);
          }}
        >
          SYNC
        </button>
      );
    }
  };

  const renderPrint = (inspection) => {
    if (!inspection.printLink) {
      return '';
    }

    return (
      <a rel="noreferrer noopener" target="_blank" href={inspection.printLink}>
        <img className="table-icon" src={print} alt="pdf download" />
      </a>
    );
  };
  const renderErrorList = () => {
    return errors.map((error, i) => {
      let inspection;

      if (error?.inspection) {
        inspection = inspections.find(
          (insp) => insp.inspection.id === error.inspection.id
        );
      }

      return (
        <p className="error-message" key={i}>
          {error?.message || error}
          {inspection ? (
            <b onClick={() => startInspection(inspection)}>
              {' '}
              <u>Fehler ansehen.</u>
            </b>
          ) : null}
        </p>
      );
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const autoSync = async () => {
    if (isOnline && inspections && inspections.length) {
      let inspections = [];
      let companies = [];
      let locations = [];
      let systems = [];
      inspections = await getAllInspection();

      for (const index in inspections) {
        const inspection = inspections[index];

        companies = await loadData('companies');
        let comp = companies.find((comp) => comp.id === inspection.company.id);

        locations = await loadData('locations', inspection.company.id);
        let loc = locations.find((loc) => loc.id === inspection.location.id);

        systems = await loadData('systems', inspection.location.id);
        let sys = systems.find((sys) => sys.id === inspection.system.id);

        const data = {};
        data.inspection = inspection;
        data.company = comp;
        data.location = loc;
        data.system = sys;
        inspections[index] = data;
      }

      inspections
        .filter(({ inspection }) => !inspection.synced)
        // eslint-disable-next-line array-callback-return
        .map(({ inspection }) => {
          globalSaveInspection(inspection, setErrors, setSyncDisabled, setInspection);
        });
    }
  };

  useEffect(autoSync, [globalSaveInspection, inspections, isOnline, setInspection]);

  const startInspection = (insp) => {
    setInspection(insp.inspection);
    setCompany(insp.company);
    setLocation(insp.location);
    setSystem(insp.system);

    history.push('/inspection');
  };

  useEffect(() => {
    let inspections = [];
    let companies = [];
    let locations = [];
    let systems = [];
    const fetchInspectionData = async () => {
      inspections = await getAllInspection();

      for (const index in inspections) {
        const inspection = inspections[index];

        companies = await loadData('companies');
        let comp = companies.find((comp) => comp.id === inspection.company.id);

        locations = await loadData('locations', inspection.company.id);
        let loc = locations.find((loc) => loc.id === inspection.location.id);

        systems = await loadData('systems', inspection.location.id);
        let sys = systems.find((sys) => sys.id === inspection.system.id);

        const data = {};
        data.inspection = inspection;
        data.company = comp;
        data.location = loc;
        data.system = sys;
        inspections[index] = data;

        console.table('This is the single inspection: ', inspection);
      }
      setInspections(inspections);
      console.log(inspections.length)
    };

    const checkConnectionMode = async () => {
      window.addEventListener('online', () => setOnline(true));
      window.addEventListener('offline', () => setOnline(false));
      navigator.onLine ? setOnline(true) : setOnline(false);
    };

    fetchInspectionData();
    checkConnectionMode();
  }, []);

  if (!inspections) {
    return <LoadingScreen small />;
  }

  if (inspections.length === 0) {
    return (
      <>
      <Header
        headline={text.Dashboard.headline}
        newInspection={text.general.newInspection}
      />
      <div className="inspection-empty-container">
        <h3>{text.Dashboard.noDataHead}</h3>
        <p>{text.Dashboard.noDataBody}</p>
      </div>
      <Footer />
      </>
    );
  }

  return (
    <>
      <Header
        headline={text.Dashboard.headline}
        newInspection={text.general.newInspection}
      />
      {renderOfflineMessage()}
      {showSyncMessage()}
      {renderErrorList()}
      <Table hover striped bordered variant="blue">
        <thead>
          <tr>
            <th className="column-inspection">{text.Dashboard.columns.inspections}</th>
            <th className="column-date">{text.Dashboard.columns.date}</th>
            <th className="column-states">{text.Dashboard.columns.state}</th>
            <th className="column-icon"></th>
          </tr>
        </thead>
        <tbody>
          {inspections
            .sort(sortByDate)
            .sort(sortSignedLast)
            .sort(sortSyncedLast)
            .map((data) => (
              <tr
                className="table-inspection"
                onClick={() => {
                  startInspection(data);
                }}
                key={data.inspection.id}
              >
                <td className="row-inspection">
                  <div>
                    <img className="table-icon" src={company} alt="pdf download" />
                    <span>{data.company.name}</span>
                  </div>

                  <div>
                    <img className="table-icon" src={system} alt="pdf download" />
                    <span>{data.system.name}</span>
                  </div>

                  <div>
                    <img className="table-icon" src={location} alt="pdf download" />
                    <span> {data.location.name}</span>
                  </div>
                </td>
                <td>{data.inspection.date}</td>
                <td>{renderState(data.inspection)}</td>
                <td
                  className="table-icon-column"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {renderSync(data.inspection)}
                  {renderPrint(data.inspection)}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div style={{ opacity: 0 }}>
        {[xLogo, vLogo, company, system, location].map((icon) => (
          <img key={icon} alt={''} src={icon} />
        ))}
      </div>
      <Footer />
    </>
  );
};

export default DashboardTable;
