import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { loadData } from '../../contexts/ApiHandler';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

const Home = () => {
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      let currentUser = loadData('currentUser');
      if (currentUser !== null) {
        console.log(currentUser);
        setHasPermission(true);
      } else {
        setHasPermission(false);
      }
    } catch (error) {
      setHasPermission(false);
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return hasPermission ? <Redirect to="./dashboard" /> : <Redirect to="./login" />;
};

export default Home;
