import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import text from '../../../text';
import inspectionContext from '../../../contexts/InspectionContext';

const GeneralInfoTab = () => {
  const { system, inspection, setInspection } = useContext(inspectionContext);
  const [operatingModeValue, setOperatingMode] = useState(
    parseInt(system.operating_mode)
  );
  const [recom_shift, setRecomShift] = useState(inspection.recom_shift);
  const testInterval = useMemo(() => [2, 6], []);

  // const operatingMode = [text.InspectionCard.generalInfo.multi, text.InspectionCard.generalInfo.single];
  const handleOperatingMode = (value) => {
    setOperatingMode(parseInt(value));
    handleChange('operating_mode', parseInt(value));
  };

  const handleChange = useCallback(
    (key, value) => {
      inspection[key] = value;
      setInspection(inspection);
    },
    [inspection, setInspection]
  );

  useEffect(() => {
    // operating_mode also got a direct effect on recom_shift
    const recom_shift = testInterval[operatingModeValue];
    setRecomShift(recom_shift);
    handleChange('recom_shift', parseInt(recom_shift));
  }, [operatingModeValue, setRecomShift, handleChange, testInterval]);

  inspection.risk_assessment = inspection.risk_assessment ?? system.risk_assessment;
  inspection.operating_mode =
    inspection.operating_mode ?? parseInt(system.operating_mode);
  inspection.machine_manual = inspection.machine_manual ?? system.machine_manual;
  inspection.change_interval_manufacturer =
    inspection.change_interval_manufacturer ?? system.change_interval_manufacturer;
  inspection.circuit_diagram = inspection.circuit_diagram ?? system.circuit_diagram;
  inspection.change_interval_operator_time =
    inspection.change_interval_operator_time ?? system.change_interval_operator_time;
  inspection.change_interval_operator_text =
    inspection.change_interval_operator_text ?? system.change_interval_operator_text;
  inspection.special_agreement_text = inspection.special_agreement_text ?? '';
  inspection.examtype = inspection.examtype ?? system.exam_type;
  inspection.testIntervalMonth =
    inspection.testIntervalMonth ?? system.test_interval_month;
  inspection.nameSignerEmployer =
    inspection.nameSignerEmployer ?? system.nameSignerEmployer;
  inspection.emailSignerEmployer =
    inspection.emailSignerEmployer ?? system.emailSignerEmployer;
  inspection.anomalies = inspection.anomalies ?? system.abnormalities;
  inspection.recom_shift =
    inspection.recom_shift ?? testInterval[inspection.operating_mode];

  return (
    <div className="row mt-3 generalInfo-container ">
      {/* First column */}

      <Col>
        <div className="row">
          <div className="col-lg-12 mb-3">
            <Form.Group controlId="systemType">
              <Form.Label>{text.InspectionCard.generalInfo.systemType}</Form.Label>
              <Form.Control
                type="text"
                readOnly
                defaultValue={system.system_type}
              />
            </Form.Group>
          </div>

          <Form.Group className="col-lg-12 mb-3 column form-group">
            <Form.Label className="radio-headline">
              {text.InspectionCard.generalInfo.operatingMode}
            </Form.Label>
            <div className="radioPills flex justify-content-start">
              <input
                className="radio-input"
                type="radio"
                name="operatingMode"
                id="operatingMultiShift"
                checked={operatingModeValue === 0}
                onChange={(e) => {
                  handleOperatingMode(0);
                }}
              />
              <label className="radio-label" htmlFor="operatingMultiShift">
                {text.InspectionCard.generalInfo.multi}
              </label>
              <input
                className="radio-input"
                type="radio"
                name="operatingMode"
                id="operatingOneShift"
                required
                checked={operatingModeValue === 1}
                onChange={(e) => {
                  handleOperatingMode(1);
                }}
              />
              <label className="radio-label" htmlFor="operatingOneShift">
                {text.InspectionCard.generalInfo.single}
              </label>
            </div>
          </Form.Group>

          <div className="col-lg-12 mb-3">
            <Form.Group controlId="changeIntervalManufacturer">
              <Form.Label>
                {text.InspectionCard.generalInfo.changeIntervalManufacturer}
              </Form.Label>
              <Form.Control
                type="number"
                min="0"
                defaultValue={inspection.change_interval_manufacturer ?? 0}
                onChange={(e) => {
                  handleChange('change_interval_manufacturer', e.target.value);
                }}
              />
            </Form.Group>
          </div>

          <div className="col-lg-12 mb-3">
            <Form.Group controlId="changeIntervalOperatorTime">
              <Form.Label>
                {text.InspectionCard.generalInfo.changeIntervalOperatorTime}
              </Form.Label>
              <Form.Control
                type="number"
                min="0"
                defaultValue={inspection.change_interval_operator_time ?? 0}
                onChange={(e) => {
                  handleChange('change_interval_operator_time', e.target.value);
                }}
              />
            </Form.Group>
          </div>

          <div className="col-lg-12 mb-3">
            <Form.Group controlId="recommend">
              <Form.Label>{text.InspectionCard.generalInfo.recommend}</Form.Label>
              <Form.Control
                type="number"
                min="0"
                value={recom_shift}
                disabled={true}
                onChange={(e) => {
                  handleChange('recom_shift', e.target.value);
                }}
              />
            </Form.Group>
          </div>
        </div>
      </Col>

      {/* Second column */}

      <Col>
        <div className="row">
          {/* Buttons with vorhanden / nicht-vorhanden  */}
          <Form.Group className="col-lg-12 mb-3 column form-group">
            <Form.Label className="radio-headline">
              {text.InspectionCard.generalInfo.riskAssessment}
            </Form.Label>
            <div className="radioPills flex justify-content-start">
              <input
                className="radio-input"
                type="radio"
                name="risk_assessment"
                id="risk-available"
                required
                defaultChecked={inspection.risk_assessment === true}
                onChange={(e) => {
                  handleChange('risk_assessment', true);
                }}
              />
              <label className="radio-label" htmlFor="risk-available">
                {text.general.available}
              </label>

              <input
                className="radio-input"
                type="radio"
                name="risk_assessment"
                id="risk-non-available"
                defaultChecked={inspection.risk_assessment === false}
                onChange={(e) => {
                  handleChange('risk_assessment', false);
                }}
              />
              <label className="radio-label" htmlFor="risk-non-available">
                liegt nicht vor
              </label>
            </div>
          </Form.Group>

          <Form.Group className="col-lg-12 mb-3 column form-group">
            <Form.Label className="radio-headline">
              {text.InspectionCard.generalInfo.manual}
            </Form.Label>
            <div className="radioPills flex justify-content-start">
              <input
                className="radio-input"
                type="radio"
                name="manual"
                id="manual-available"
                required
                defaultChecked={inspection.machine_manual === true}
                onChange={(e) => {
                  handleChange('machine_manual', true);
                }}
              />
              <label className="radio-label" htmlFor="manual-available">
                {text.general.available}
              </label>

              <input
                className="radio-input"
                type="radio"
                name="manual"
                id="manual-non-available"
                defaultChecked={inspection.machine_manual === false}
                onChange={(e) => {
                  handleChange('machine_manual', false);
                }}
              />
              <label className="radio-label" htmlFor="manual-non-available">
                {text.general.not_available}
              </label>
            </div>
          </Form.Group>

          <Form.Group className="col-lg-12 mb-3 column form-group">
            <Form.Label className="radio-headline">
              {text.InspectionCard.generalInfo.circuitDiagram}
            </Form.Label>
            <div className="radioPills flex justify-content-start">
              <input
                className="radio-input"
                type="radio"
                name="circuit"
                id="circuit-available"
                required
                defaultChecked={inspection.circuit_diagram === true}
                onChange={(e) => {
                  handleChange('circuit_diagram', true);
                }}
              />
              <label className="radio-label" htmlFor="circuit-available">
                {text.general.available}
              </label>

              <input
                className="radio-input"
                type="radio"
                name="circuit"
                id="circuit-non-available"
                defaultChecked={inspection.circuit_diagram === false}
                onChange={(e) => {
                  handleChange('circuit_diagram', false);
                }}
              />
              <label className="radio-label" htmlFor="circuit-non-available">
                {text.general.not_available}
              </label>
            </div>
          </Form.Group>

          <div className="col-lg-12 mb-3">
            <Form.Group controlId="changeIntervalOperatorText">
              <Form.Label>
                {text.InspectionCard.generalInfo.changeIntervalOperatorText}
              </Form.Label>

              <Form.Control
                as="textarea"
                rows={2}
                defaultValue={inspection.change_interval_operator_text}
                onChange={(e) => {
                  handleChange('change_interval_operator_text', e.target.value);
                }}
                placeholder="Wann, wer und warum"
              />
            </Form.Group>
          </div>

          <div className="col-lg-12 mb-3">
            <Form.Group controlId="specialAgreementOperatorText">
              <Form.Label>
                {text.InspectionCard.generalInfo.specialAgreementOperatorText}
              </Form.Label>

              <Form.Control
                as="textarea"
                rows={2}
                defaultValue={inspection.special_agreement_text}
                onChange={(e) => {
                  handleChange('special_agreement_text', e.target.value);
                }}
                placeholder="Sondervereinbarungen"
              />
            </Form.Group>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default GeneralInfoTab;
