import React from 'react';
import { Card, Container } from 'react-bootstrap';
import Header from '../../components/Header';
import text from '../../text';
import SubmitBody from './SubmitBody';
import './submitScreen.scss';

const SubmitScreen = () => {
  return (
    <Container className="submitScreen">
      <Header
        headline={text.SubmitScreen.headline}
        newInspection={text.general.newInspection}
      />
      <Card>
        <SubmitBody />
      </Card>
    </Container>
  );
};

export default SubmitScreen;
