const text = {
  general: {
    version: 'v. 0.9.1', // please also update the version in package.json
    back: 'voriger Reiter',
    next: 'nächster Reiter',
    save: 'Speichern',
    saveSignature: 'Unterschrift speichern',
    cancel: 'Abbrechen',
    create: 'Anlegen',
    email: 'E-Mail',
    password: 'Passwort',
    logout: 'Log out',
    admin: 'Zum Verwaltungsbereich',
    newInspection: 'Neue Prüfung',
    clear: 'Zurücksetzen',
    send: 'Absenden',
    available: 'vorhanden',
    not_available: 'nicht vorhanden',
    offlineMessage:
      'Es besteht aktuell keine Internetverbindung. Alle Änderungen, die Sie jetzt vornehmen, werden in dieser Zeit auf dem Gerät gespeichert',
    backTo: 'Zurück zu'
  },
  login: {
    submit: 'Login',
    forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
    protocol: 'Protokoll',
    management: 'Verwaltung',
    resetPassword: 'Passwort zurücksetzen',
    successResetMessage:
      'Vielen dank, in wenigen Augenblicken wird Ihnen eine Email zugesandt.',
    errorResetMessage:
      'Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut.',
    errorLoginMessage: 'Ihre Email und Passwort stimmen nicht überein.'
  },
  InspectionCard: {
    tabs: {
      info: 'Info',
      details: 'Angaben',
      inspect: 'Durchführung',
      result: 'Ergebnis'
    },
    generalInfo: {
      systemType: 'Anlagentyp',
      operatingMode: 'Betriebsart',
      changeIntervalManufacturer: 'Wechselintervall Hersteller (Jahre)',
      recommend: 'Empfohlener Wechselintervall (Jahre)',
      changeIntervalOperatorTime: 'Wechselintervall Betreiber (Jahre)',
      changeIntervalOperatorText: 'Wechselintervall Betreiber - Extra Info',
      specialAgreementOperatorText: 'Sondervereinbarung',
      riskAssessment: 'Gefährdungsbeurteilung inkl. Hydraulik',
      manual: 'Maschinenhandbuch',
      circuitDiagram: 'Schaltplan',
      checkedTubeTypes: 'Geprüfte Schlaucharten',
      multi: 'Mehrschichtbetrieb',
      single: 'Einzelschichtbetrieb'
    },
    header: {
      customer: 'Kunde',
      system: 'Anlage',
      systemNumber: 'Nr.',
      location: 'Standort'
    },
    inspectionInfo: {
      checkType: 'Prüfart',
      testBasis: 'Prüfgrundlage',
      testIntervalMonth: 'Prüfintervall Monate (Bisher)',
      testIntervalRecommend: 'Prüfintervall Monate (Empfehlung) bei',
      testBasisMessage: 'DGUV 113-020 (ehemals BGR237) TRBS 1201, DIN 20066 / 2018-3'
    },
    carriedOutInspection: {
      newTube: 'Neuer Schlauch',
      deleteTube: 'Schlauch löschen',
      editTube: 'Schlauch bearbeiten',
      notSavedEditTube:
        'Bitte speichern Sie die Prüfung, bevor Sie einen Schlauch bearbeiten.',
      newTubeHeadline: 'Bitte füllen Sie die folgenden Angaben aus',
      selectTube: 'Schlauchauswahl',
      selectedTube: 'Ausgewählter Schlauch',
      rating: 'NW',
      name: 'Bezeichnung',
      protectiveMeasures: 'vorhandene Schutzmaßnahmen',
      tubeType: 'Schlauchart',
      testDate: 'Prüfdatum',
      examiner: 'zur Prüfung befähigte Person nach TRBS 1203',
      result: 'Ergebnis',
      defects: 'Mängel',
      activities: 'Maßnahmen',
      construction_year: 'Baujahr',
      amount: 'Menge',
      ruptureProtection: 'Berstschutz',
      rope: 'Fangseile',
      tube_number: 'Schlauchnummer',
      good: 'OK',
      notGood: 'Nicht OK',
      tip: 'Hinweise',
      tubeLocation: 'Einbauort',
      buildUp: 'ausgebaut',
      more: 'Weitere Optionen',
      moreinfo: 'Extra Info',
      notubemessage1: 'Noch kein Schlauch für die Anlage angelegt.',
      notubemessage2: 'Bitte legen Sie über den "Neuer Schlauch"-Button den ersten an.',
      nameValidation: 'Bitte geben Sie eine Bezeichnung an',
      tubeNumberValidation: 'Bitte geben Sie eine Schlauchnummer an',
      consYearValidation: 'Bitte geben Sie ein Baujahr an',
      amountValidation: 'Bitte geben Sie eine bestimmte Menge an',
      tubeTypeValidation: 'Bitte wählen Sie eine Schlauchart aus'
    },
    offline:
      'Leider besteht aktuell keine Internetverbindung. Alle Ihre Änderungen wurden jedoch auf dem Gerät gespeichert.',
    alreadySynced:
      'Diese Prüfung wurde in der Vergangenheit schon einmal Übertragen. Eine Aktualisierung ist nicht möglich.'
  },
  TubeDeleteModal: {
    headline: 'Schlauch löschen',
    confirmation: 'Sind Sie sicher, sie wollen diesen Schlauch löschen?',
    delete: 'Löschen'
  },
  SubmitScreen: {
    headline: 'Zustand',
    congratulationsMessage: 'Das hat geklappt!',
    offlineMessage:
      'Sie sind aktuell nicht online. Ihre Daten wurden erfolgreich gespeichert, jedoch noch nicht übertragen. Wenn Sie das nächste mal online gehen, werden diese synchronisiert und im Dashboard als erledigt markiert.',
    onlineMessage:
      'Ihre Daten wurden erfolgreich gespeichert und übertragen, Sie können dies im Dashboard überprüfen.',
    toDashboard: 'Zum Dashboard',
    overallResult: 'Gesamtergebnis'
  },
  Dashboard: {
    headline: 'Dashboard',
    noDataHead: 'Keine Prüfungen vorhanden',
    saveMessage:
      'Bitte das finale Speichern der Prüfungen auf dem Server in den jeweiligen Prüfungen durchführen!',
    noDataBody:
      'Es liegen aktuell keine Prüfungen vor. Bitte erstellen Sie eine neue, um mit der Prüfung zu beginnen.',
    columns: {
      inspections: 'Prüfungen',
      date: 'Datum',
      state: 'Zustand'
    },
    state: {
      completed: 'abgeschlossen',
      signed: 'unterschrieben',
      synced: 'übertragen'
    }
  },
  NewInspection: {
    labels: {
      clientName: 'Wie heißt der Kunde?',
      location: 'Standort',
      system: 'Anlage',
      hbNumber: 'HB-Nr',
      lastCheck: 'Letzte Prüfung'
    },
    toolTip: {
      company: 'Neuen Kunden erstellen',
      location: 'Neuen Standort erstellen',
      system: 'Neue Anlage erstellen'
    },
    errors: {
      company: 'Bitte wählen Sie einen Kunden aus',
      location: 'Bitte wählen Sie ein Standort aus',
      system: 'Bitte wählen Sie eine Anlage aus',
      hbnumber: 'Bitte geben Sie eine Auftragsnummer an'
    }
  },
  ResultsTab: {
    labels: {
      testerData: 'Daten des Prüfers',
      companyData: 'Daten des Arbeitgebers',
      name: 'Name, Abteilung / Position des Arbeitgebers',
      email: 'Email des Arbeitgebers',
      emailCC:
        'Email CC (Sie können mehrere E-Mail Adressen eingeben, indem Sie mit Kommata trennen)',
      overallResult: 'Gesamtergebnis der Prüfung',
      anomalies: 'Auffälligkeiten',
      errorCodes: 'Fehlercodes ablegen',
      isSigned: 'Unterschrift wurde gespeichert',
      isNotSigned:
        'Zur Bestätigung der Prüfergebnisse unterschreiben Sie bitte in folgendem Feld:'
    },
    info: {
      infoTitle: 'Kenntnisnahme durch den Arbeitgeber:',
      infoFirstSentence:
        'Der Arbeitsgeber wurde über das Prüfergebnis, die festgestellten Mängel und Abweichungen der betreffenden Schlauchleitungen im Prüfprotokoll informiert.',
      infoSecondSentence:
        'Das Prüfprotokoll sollte dem Maschinenhandbuch hinzugefügt werden.'
    }
  },
  NoPermission: {
    headline: 'fehlende Berechtigungen',
    sentenceProblem: 'Diese Seite ist nur für Techniker und Prüfer freigegeben.',
    sentenceAlternative: 'Bitte wenden Sie sich im Zweifelsfall an Ihren Administrator.'
  },
  selectTube: {
    headline: 'Schlauch auswählen',
    new: 'Neuer Schlauch'
  },
  errors: {
    corruptedData:
      'Es ist ein Datenfehler aufgetreten, bitte überprüfen Sie Ihre Eingaben auf Fehler.',
    corruptedNetwork:
      'Es ist ein Netzwerkfehler aufgetreten, bitte versuchen Sie es später erneut.',
    missingSignature: 'Es fehlt mindestens eine Unterschrift (Tab "Ergebnis")',
    missingExamType: 'Es fehlt die Prüfart (Tab "Angaben")',
    missingNameSigner:
      'Bitte füllen Sie das "Name, Abteilung / Position des Arbeitgebers" Feld (Tab "Ergebnis")',
    changeIntervalOperatorTime:
      'Das Feld "Wechselintervall Betreiber - Zeit" muss eine Zahl größer 0 beinhalten (Tab "Info")',
    isNotValidValue: 'ist kein erlaubter Wert',
    emailSignerEmployer:
      'Das Feld "Email des Arbeitgebers" ist leer oder im falschen Format',
    testIntervalMonth:
      'Das Feld "Prüfintervall Monate (Bisher)" ist leer oder im falschen Format'
  }
};

export default text;
