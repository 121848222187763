import { TubeType } from '../types/tubes';

export const sortAlphabetically = (current: string, next: string) => {
  if (current < next) return -1;
  if (current > next) return 1;
  return 0;
};

export const sortAlphaNumeric = (current: number, next: number) => {
  if (!!current && !next) return -1;
  if (!current && !!next) return 1;

  if (current < next) return -1;
  if (current > next) return 1;
  return 0;
};

export const sortByTubeNumber = (
  current: TubeType['tube_number'],
  next: TubeType['tube_number']
) => {
  if (current && !next) return -1;
  if (!current && next) return 1;
  if (!current && !next) return 0;

  // Get the number from the string
  let currentNumber = current.match(/\d+/);
  let nextNumber = next.match(/\d+/);

  if (currentNumber) {
    current = currentNumber[0];
  } else {
    current = '0';
  }

  if (nextNumber) {
    next = nextNumber[0];
  } else {
    next = '0';
  }

  let numericCurrent = parseInt(current);
  let numericNext = parseInt(next);

  if (numericCurrent || numericNext) return sortAlphaNumeric(numericCurrent, numericNext);

  if (numericCurrent < numericNext) return -1;
  if (numericCurrent > numericNext) return 1;
  return 0;
};

export const mapArrayToObject = (array: any[], property: string) => {
  const obj: any = {};
  array.forEach((item) => {
    if (!obj[item[property]]) {
      obj[item[property]] = [item];
    } else {
      obj[item[property]].push(item);
    }
  });

  return obj;
};

export const sortArraysInObject = (obj: any, sortFunction: Function) => {
  const newObj: any = {};
  for (let key in obj) {
    newObj[key] = obj[key].sort(sortFunction);
  }
  return newObj;
};

export const mapObjectToArray = (obj: any) => {
  const array: any[] = [];
  for (let key in obj) {
    array.push(...obj[key]);
  }
  return array;
};

export const handleTubeColor = (result: string) => {
  switch (result) {
    case '0':
      return 'circle_green';
    case '1':
      return 'circle_orange';
    case '2':
      return 'circle_red';
    default:
      return 'circle_gray';
  }
};

export const ellipsedText = (
  text = 'Es gab Probleme mit den ...',
  maxAmountOfWords = 6
) => {
  const words = text.split(' ');
  if (words.length <= maxAmountOfWords) {
    return text;
  } else {
    const ellipsedWords = words.slice(0, maxAmountOfWords);
    return `${ellipsedWords.join(' ')}...`;
  }
};
