import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo';

type HeaderProps = {
  headline: string;
  newInspection: string;
};

const Header = ({ headline, newInspection }: HeaderProps) => {
  return (
    <>
      <header className="header">
        <Logo size="small" />
        <div className="header-text">
          <h1 className="header-title">{headline}</h1>
          <Link className="btn" to="/new-inspection">
            {newInspection}
          </Link>
        </div>
      </header>
    </>
  );
};

export default Header;
