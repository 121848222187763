import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Nav, Button } from 'react-bootstrap';
import GeneralInfoTab from '../InspectionTabs/GeneralInfoTab';
import InspectionInfoTab from '../InspectionTabs/InspectionInfoTab';
import CarriedOutInspectionTab from '../InspectionTabs/CarriedOutInspectionTab';
import ResultsTab from '../InspectionTabs/ResultsTab';
import text from '../../text';
import './inspectionCard.scss';
import LoadingButton from '../LoadingButton';
import inspectionContext from '../../contexts/InspectionContext';
import Footer from '../Footer/Footer';
import Offline from '../../partials/Offline';
import Debug from '../Debug/Debug';

export default function InspectionCard() {
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  let [errors, setErrors] = useState([]);
  const history = useHistory();
  let {
    inspection,
    setInspection,
    saveInspection: globalSaveInspection
  } = useContext(inspectionContext);

  const nextPage = () => {
    let number = page;
    number++;
    setPage(number);
  };
  const previousPage = () => {
    let number = page;
    number--;
    setPage(number);
  };

  // TODO the validation here with the saving function
  const saveInspection = async () => {
    const result = await globalSaveInspection(
      inspection,
      setErrors,
      setIsLoading,
      setInspection
    );

    if (result === true) history.push('/result');
  };

  const renderOfflineMessage = () => {
    return (
      <Offline>
        <div className="offline-message">{text.general.offlineMessage}</div>
      </Offline>
    );
  };

  const getLastPageIndex = () => {
    const pageComponent = [
      GeneralInfoTab,
      InspectionInfoTab,
      CarriedOutInspectionTab,
      ResultsTab
    ];
    return pageComponent.length - 1;
  };

  const CurrentComponent = useMemo(() => {
    const pageComponent = [
      GeneralInfoTab,
      InspectionInfoTab,
      CarriedOutInspectionTab,
      ResultsTab
    ];
    return pageComponent[page];
  }, [page]);

  const tabs = text.InspectionCard.tabs;

  return (
    <>
      <Debug />
      {renderOfflineMessage()}
      <Card className="cardContainer">
        <Card.Header>
          <Nav variant="pills" justify defaultActiveKey="/home">
            <Nav.Item>
              <Nav.Link active={page === 0} onClick={() => setPage(0)}>
                {tabs.info}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={page === 1} onClick={() => setPage(1)}>
                {tabs.details}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={page === 2} onClick={() => setPage(2)}>
                {tabs.inspect}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={page === 3} onClick={() => setPage(3)}>
                {tabs.result}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body>
          <CurrentComponent />
          {errors?.map((error, i) => (
            <p className="error-message" key={i}>
              {error?.message || error}
            </p>
          ))}
        </Card.Body>
        <Card.Footer>
          <div className="tab-buttons d-flex justify-content-end">
            {page > 0 && (
              <Button onClick={() => previousPage()}>{text.general.back}</Button>
            )}
            {page < getLastPageIndex() && (
              <Button className="ml-3" onClick={() => nextPage()}>
                {text.general.next}
              </Button>
            )}
            {page === getLastPageIndex() && !isLoading && (
              <Button
                className="ml-3"
                disabled={inspection.synced}
                onClick={() => saveInspection()}
              >
                <span>{text.general.save}</span>
              </Button>
            )}
            {page === getLastPageIndex() && isLoading && (
              <LoadingButton text={text.general.save} />
            )}
          </div>
        </Card.Footer>
      </Card>
      <Footer />
    </>
  );
}
