import localforage from 'localforage';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { loadData } from './ApiHandler';

export const getCurrentDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentDateTime = () => {
  var today = new Date();
  var hh = today.getHours();
  var MM = today.getMinutes();
  var ss = today.getSeconds();

  return `${getCurrentDate()} ${hh}:${MM}:${ss}`;
};

export const getCurrentDateAmerican = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

const setInspections = async (inspections) => {
  return setItem('inspections', inspections);
};

export async function addInspection(inspection) {
  inspection.id = uuidv4();
  inspection.date = getCurrentDate();
  inspection.lastUpdated = getCurrentDateTime();
  inspection.completed = false;
  inspection.signed = false;
  inspection.synced = false;
  const inspections = await getAllInspection();
  inspections.push(inspection);
  await setInspections(inspections);
  return inspection;
}

export async function updateInspection(inspection) {
  const inspections = await localforage.getItem('inspections');
  const index = inspections.findIndex((i) => i.id === inspection.id);

  if (index === -1) {
    return;
  }

  inspection.date = getCurrentDate();
  inspection.lastUpdated = getCurrentDateTime();
  inspection.id = inspection.newExamId ? inspection.newExamId : inspection.id;

  inspections[index] = inspection;
  return setInspections(inspections);
}

export async function getAllInspection() {
  const inspList = await loadData('exams');
  console.log('[[ THESE ARE THE EXAMS ]]', inspList); // 44
  // console.log('exams', inspList)

  let deletedInspectionIdList = inspList
    .filter((inspection) => inspection.deleted === true)
    .map((inspection) => inspection.exam);
  console.log('[[ THESE ARE THE DELETED EXAMS ]]', deletedInspectionIdList);
  
  const inspections = (await getItem('inspections')).filter(
    (inspection) =>
      !deletedInspectionIdList || !deletedInspectionIdList.includes(inspection.id)
  );
  console.log('[[ THESE ARE THE INSPECTIONS ]]', inspections);

  // setInspections(inspections);
  // console.log('inspections', inspections)
  return inspections;
}

export async function getInspection(id) {
  const inspections = await getAllInspection();
  return inspections.find((i) => i.id === id);
}

export async function getAllHbNumber() {
  const inspections = await getAllInspection();
  const numberList = inspections
    .map((i) => i.orderNumber)
    .filter((value, index, self) => self.indexOf(value) === index);

  return numberList;
}

export async function getItem(itemName) {
  const item = await localforage.getItem(itemName);

  if (!item) {
    return [];
  }

  return item;
}

export function setItem(itemName, item) {
  return localforage.setItem(itemName, item);
}

export async function getLastUpdatedOfSystem(systemId) {
  let inspections = await getAllInspection();
  const systemDates = inspections
    .filter((inspection) => inspection.system.id === systemId)
    .sort(sortByDate);
  if (!systemDates || systemDates.length === 0) {
    return;
  }

  return systemDates[0].date;
}

const sortByDate = (current, next) => {
  const dateCurrent = current.lastUpdated ? current.lastUpdated : current.date;
  const dateNext = next.lastUpdated ? next.lastUpdated : next.date;

  const currentTs = moment(dateCurrent, 'YYYY.MM.DD hh:mm:ss').unix();
  const nextTs = moment(dateNext, 'YYYY.MM.DD hh:mm:ss').unix();

  if (currentTs < nextTs) return 1;

  if (currentTs > nextTs) return -1;

  return 0;
};
