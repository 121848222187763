/* eslint-disable */
import moment from 'moment';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { loadData } from '../../../contexts/ApiHandler';
import inspectionContext from '../../../contexts/InspectionContext';
import Select from '../../../partials/WsSelect';
import text from '../../../text';
import { TubeType } from '../../../types/tubes';

export const tubeBaseStructure = {
  date: '',
  result: '',
  defects: [],
  activities: '',
  name: '',
  protective_measures: [],
  construction_year: '',
  tubeType: null,
  amount: 0,
  ruptureProtection: false,
  rope: false,
  tube_number: '',
  id: '',
  tubeLocation: '',
  removed: false
};
// satisfies TubeType;

type Props = {
  setNewTube: Function;
  setCreatedTube: Function;
  allNewTubes: TubeType[];
  setAllNewTubes: Function;
};

export default function AddTube({
  setCreatedTube,
  setNewTube,
  allNewTubes,
  setAllNewTubes
}: Props) {
  let { addTube } = useContext(inspectionContext);
  const [validationName, setValidationName] = useState(false);
  const [validationTubeNumber, setValidationTubeNumber] = useState(false);
  const [validationConstructionYear, setValidationConstructionYear] = useState(false);
  const [validationAmount, setValidationAmount] = useState(false);
  const [selectedTubeType, setSelectedTubeType] = useState<number | null>(null);
  const [validationTubeType, setValidationTubeType] = useState(false);
  const [selectedConstructionYear, setSelectedConstructionYear] = useState<any>(null);

  const [createTube, setCreateTube] = useState<TubeType>({ ...tubeBaseStructure });
  const [tubeTypes, setTubeTypes] = useState<{ label: string; value: number }[]>([]);

  // Changers and handlers for inputs

  const changeCreateTubeValue = <K extends keyof TubeType>(
    value: TubeType[K],
    key: K
  ) => {
    const updatedCreateTube = {
      ...createTube,
      [key]: value
    };

    setCreateTube(updatedCreateTube);
    setAllNewTubes([...allNewTubes, updatedCreateTube]);
  };

  const handleConstructionYearChange = (inputDateString: any) => {
    const date = moment(inputDateString).format('DD.MM.YYYY');
    changeCreateTubeValue(date, 'construction_year');
    setSelectedConstructionYear(inputDateString);
  };

  const changeCreateTubeChecked = (e: any, key: 'ruptureProtection' | 'rope') => {
    createTube[key] = !!e.target.checked as boolean;
    setCreateTube(createTube);
  };

  const handleTubeTypeChange = (
    e: { value: number; label: string } | null,
    key: 'tubeType'
  ) => {
    setSelectedTubeType(e?.value ?? null);
    createTube[key] = e?.value ?? null;
    setCreateTube(createTube);
  };

  async function submitNewTube(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (createTube.name === '') {
      setValidationName(true);
      return;
    } else {
      setValidationName(false);
    }

    if (createTube.tube_number === '') {
      setValidationTubeNumber(true);
      return;
    } else {
      setValidationTubeNumber(false);
    }
    if (createTube.construction_year === '') {
      setValidationConstructionYear(true);
      return;
    } else {
      setValidationConstructionYear(false);
    }
    if (createTube.amount < 0) {
      setValidationAmount(true);
      return;
    } else {
      setValidationAmount(false);
    }

    if (createTube.tubeType === null) {
      setValidationTubeType(true);
      return;
    } else {
      setValidationTubeType(false);
    }

    const createdTube = await addTube(createTube);
    setCreatedTube(createdTube);
    setCreateTube({ ...tubeBaseStructure });
    setNewTube(false);
  }

  useEffect(() => {
    const fetchTubeType = async () => {
      const allTubeType = await loadData('tubeTypes');
      const tubeTypes = allTubeType.map((type) => {
        return { value: type.id, label: type.name };
      });
      setTubeTypes(tubeTypes);
    };
    fetchTubeType();
  }, []);

  return (
    <Form className="carriedOut-container mt-3" onSubmit={(e) => submitNewTube(e)}>
      <div className="input-section input-section-full">
        <h3 className="input-section-headline">
          {text.InspectionCard.carriedOutInspection.newTubeHeadline}
        </h3>
        <Form.Group controlId="name">
          <Form.Label>{text.InspectionCard.carriedOutInspection.name}</Form.Label>
          <Form.Control
            onChange={(e) => changeCreateTubeValue(e.target.value, 'name')}
            type="text"
          />
        </Form.Group>

        <Form.Group controlId="tube_number">
          <Form.Label>{text.InspectionCard.carriedOutInspection.tube_number}</Form.Label>
          <Form.Control
            onChange={(e) => changeCreateTubeValue(e.target.value, 'tube_number')}
            type="text"
          />
        </Form.Group>

        <Form.Group controlId="construction_year">
          <Form.Label>
            {text.InspectionCard.carriedOutInspection.construction_year}
          </Form.Label>
          <DatePicker
            className="form-control"
            format="dd.MM.yyyy"
            maxDate={new Date()}
            value={selectedConstructionYear}
            showLeadingZeros={true}
            onChange={(dateString: any) => handleConstructionYearChange(dateString)}
          />
        </Form.Group>
        <Form.Group controlId="amount">
          <Form.Label>{text.InspectionCard.carriedOutInspection.amount}</Form.Label>
          <Form.Control
            type="number"
            min="1"
            placeholder="1"
            onChange={(e) => changeCreateTubeValue(+e.target.value, 'amount')}
          />
        </Form.Group>

        <Form.Group controlId="tubeType">
          <Form.Label>{text.InspectionCard.carriedOutInspection.tubeType}</Form.Label>
          <Select
            options={tubeTypes}
            value={tubeTypes.find((obj) => obj.value === selectedTubeType)}
            onChange={(e: any) => handleTubeTypeChange(e, 'tubeType')} // assign onChange function
            styles={{
              control: (base) => ({
                ...base,
                minWidth: '300px'
              })
            }}
          />
        </Form.Group>

        <div className="radioPills-containerNewTube">
          <div>{text.InspectionCard.carriedOutInspection.moreinfo}</div>
          <div className="radioPills radioPills-newTube">
            <input
              className="radio-input"
              type="checkbox"
              name="ruptureProtection"
              id="ruptureProtection"
              onClick={(e) => changeCreateTubeChecked(e, 'ruptureProtection')}
            />
            <label className="radio-label" htmlFor="ruptureProtection">
              {text.InspectionCard.carriedOutInspection.ruptureProtection}
            </label>

            <input
              className="radio-input"
              type="checkbox"
              name="rope"
              id="rope"
              onClick={(e) => changeCreateTubeChecked(e, 'rope')}
            />
            <label className="radio-label" htmlFor="rope">
              {text.InspectionCard.carriedOutInspection.rope}
            </label>
          </div>
        </div>

        {validationName ? (
          <div className="error-message">
            {text.InspectionCard.carriedOutInspection.nameValidation}
          </div>
        ) : null}
        {validationTubeNumber ? (
          <div className="error-message">
            {text.InspectionCard.carriedOutInspection.tubeNumberValidation}
          </div>
        ) : null}
        {validationConstructionYear ? (
          <div className="error-message">
            {text.InspectionCard.carriedOutInspection.consYearValidation}
          </div>
        ) : null}
        {validationAmount ? (
          <div className="error-message">
            {text.InspectionCard.carriedOutInspection.amountValidation}
          </div>
        ) : null}
        {validationTubeType ? (
          <div className="error-message">
            {text.InspectionCard.carriedOutInspection.tubeTypeValidation}
          </div>
        ) : null}
        <div className="buttons-container">
          <button className="btn" onClick={() => setNewTube(false)}>
            zur {text.InspectionCard.carriedOutInspection.selectTube}
          </button>
          <Button className="btn btn-success mr-2" type="submit">
            {text.general.save}
          </Button>
        </div>
      </div>
    </Form>
  );
}

AddTube.propTypes = {};
