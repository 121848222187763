import React from 'react';
import { AuthProvider } from './contexts/AuthContext';
import ThemeStyles from './ThemeStyles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './views/Home';
import Dashboard from './views/Dashboard';
import NewInspection from './views/NewInspection';
import Inspection from './views/Inspection';
import './assets/style/App.scss';
import Login from './components/Login/Login';
import Logout from './components/Login/Logout';
import PrivateRoute from './partials/PrivateRoute';
import SubmitScreen from './views/SubmitScreen/SubmitScreen';
import NoPermission from './views/NoPermission';
import { Container } from 'react-bootstrap';
import { Preloader } from './components/Preloader/Preloader';

function App() {
  return (
    <Container
      style={{ minHeight: '100vh' }}
      className="d-flex align-items-center justify-content-center text-center"
    >
      <ThemeStyles />
      <Router>
        <AuthProvider>
          <Preloader>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/inspection" component={Inspection} />
              <PrivateRoute path="/new-inspection" component={NewInspection} />
              <PrivateRoute path="/result" component={SubmitScreen} />
              <PrivateRoute path="/no-permission" component={NoPermission} />
            </Switch>
          </Preloader>
        </AuthProvider>
      </Router>
    </Container>
  );
}

export default App;
