import React, { useRef, useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';
import text from '../../../text';
import inspectionContext from '../../../contexts/InspectionContext';
import { ellipsedText } from '../../../utils/functions';

const ResultsTab = () => {
  const anomaliesRef = useRef(null);

  const copyIntoAnomaliesTextField = (e) => {
    const info = e.target.getAttribute('data-info'); // Get the data-info attribute // Get the ellipsed version with maximum of 6 words
    anomaliesRef.current.value += info; // Append the ellipsed version to the textarea
  };

  const [overallResults, setOverallResults] = useState('Keine Abweichungen');
  const [isTesterSigned, setIsTesterSigned] = useState(false);
  const [isCompanySigned, setIsCompanySigned] = useState(false);
  const [testerSignature, setTesterSignature] = useState('');
  const [companySignature, setCompanySignature] = useState('');
  const [disabledTesterButton, setDisabledTesterButton] = useState(false);
  const [disabledCompanyButton, setDisabledCompanyButton] = useState(false);

  const { inspection, setInspection } = useContext(inspectionContext);

  const inputResults = useRef();

  useEffect(() => {
    const targetInputResults = inputResults.current;
    if (inspection && inspection.exams) {
      if (inspection.exams.filter((t) => t.result === '0').length >= 1) {
        setOverallResults('Keine Abweichungen');
        targetInputResults.className = 'form-control results-ok';
      }

      if (inspection.exams.filter((t) => t.result === '1').length >= 1) {
        setOverallResults('geringe Abweichungen bzw. Mängel');
        targetInputResults.className = 'form-control results-regular';
      }
      if (inspection.exams.filter((t) => t.result === '2').length >= 1) {
        setOverallResults('sicherheitsrelevante Abweichungen bzw. Mängel');
        targetInputResults.className = 'form-control results-bad';
      }
    } else {
      targetInputResults.className = 'form-control results-ok';
    }

    setIsTesterSigned(
      inspection.signatureTester && inspection.signatureTester.length > 0
    );
    setIsCompanySigned(
      inspection.signatureCompany && inspection.signatureCompany.length > 0
    );
    setTesterSignature(inspection.signatureTester);
    setCompanySignature(inspection.signatureCompany);
  }, [inspection.signatureCompany, inspection.signatureTester, inspection]);

  const sigCanvas = {
    tester: useRef({}),
    company: useRef({})
  };

  function handleChange(key, value) {
    inspection[key] = value;
    setInspection(inspection);
  }

  const signature = {
    tester: '',
    company: ''
  };

  const savePad = (person) => {
    signature[person] = sigCanvas[person].current.toDataURL('image/png');

    if (person === 'tester') {
      handleChange('signatureTester', signature[person]);
      setTesterSignature(signature[person]);
      clearPad(person);
      setIsTesterSigned(true);
      setDisabledTesterButton(true);
    } else {
      handleChange('signatureCompany', signature[person]);
      setCompanySignature(signature[person]);
      clearPad(person);
      setIsCompanySigned(true);
      setDisabledCompanyButton(true);
    }
    handleChange(
      'signed',
      inspection.signatureTester &&
        inspection.signatureTester.length > 0 &&
        inspection.signatureCompany &&
        inspection.signatureCompany.length > 0
    );
  };

  const clearPad = (person) => {
    sigCanvas[person].current.clear(signature[person]);

    if (person === 'tester') {
      setIsTesterSigned(false);
      setDisabledTesterButton(false);
    } else {
      setIsCompanySigned(false);
      setDisabledCompanyButton(false);
    }
  };

  const defaultValue = {};
  if (typeof inspection.anomalies !== 'undefined') {
    defaultValue.anomalies = inspection.anomalies;
  }
  if (typeof inspection.nameSignerEmployer !== 'undefined') {
    defaultValue.nameSignerEmployer = inspection.nameSignerEmployer;
  }
  if (typeof inspection.emailSignerEmployer !== 'undefined') {
    defaultValue.emailSignerEmployer = inspection.emailSignerEmployer;
  }

  const isAnomalySuggestionVisible = (anomaly) => {
    const notEmpty = anomaly && anomaly.length > 0;
    const notSameAsLast = inspection.anomalies !== anomaliesRef.current?.value;

    if(notEmpty && notSameAsLast) {
      return true;
    }
  }

  return (
    <div className="results-tab mt-3">
      <div className="input-section">
        {/* First column  */}
        <Col className="result-col">
          <Form.Group as={Col} controlId="overallResults">
            <Form.Label>
              <strong>{text.ResultsTab.labels.overallResult}</strong>
            </Form.Label>
            <Form.Control
              ref={inputResults}
              type="name"
              value={overallResults}
              readOnly
            />
          </Form.Group>
        </Col>
        {/* Second column  */}

        <Col className="result-col">
          <Form.Group as={Col} controlId="anomalies">
            <Form.Label>
              <strong>{text.ResultsTab.labels.anomalies}</strong>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              ref={anomaliesRef}
              onChange={(e) => {
                handleChange('anomalies', e.target.value);
              }}
            />
          </Form.Group>
        </Col>

        {isAnomalySuggestionVisible(defaultValue.anomalies) ? (
          <Col>
            <div
              className="container"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '20px'
              }}
            >
              <span>Zuletzt eingetragene Auffälligkeiten</span>
              <ul style={{ display: 'flex', gap: '20px', listStyle: 'none' }}>
                <li>
                  <button
                    className="ellipsed suggestion"
                    data-info={defaultValue.anomalies}
                    onClick={(e) => copyIntoAnomaliesTextField(e)}
                  >
                    {ellipsedText(defaultValue.anomalies, 6)}
                  </button>
                </li>
              </ul>
            </div>
          </Col>
        ) : null}
      </div>

      {/* Row with the tester signature  */}
      <section className="tester-section">
        <div className="signature-headline">{text.ResultsTab.labels.testerData}</div>
        <Row className="signature-section">
          {!isTesterSigned ? (
            <p className="signature-sentence">{text.ResultsTab.labels.isNotSigned}</p>
          ) : (
            <p className="signature-sentence signature-signed">
              {text.ResultsTab.labels.isSigned}
            </p>
          )}
          <SignatureCanvas
            canvasProps={{ className: 'sigCanvas' }}
            ref={sigCanvas.tester}
          />

          {isTesterSigned ? (
            <img
              src={testerSignature}
              width="120"
              height="100"
              alt="signature"
              className="image-signature"
            />
          ) : null}

          <div className="buttons">
            <button className="btn-transparent" onClick={() => clearPad('tester')}>
              {text.general.clear}
            </button>
            <Button disabled={disabledTesterButton} onClick={() => savePad('tester')}>
              {text.general.saveSignature}
            </Button>
          </div>
        </Row>
      </section>

      {/* Row with the info message */}
      <Row className="info-section">
        <div className="info-title">{text.ResultsTab.info.infoTitle}</div>
        <p>{text.ResultsTab.info.infoFirstSentence}</p>
        <p>{text.ResultsTab.info.infoSecondSentence}</p>
      </Row>

      {/* Row with the company signature  */}
      <section className="company-section">
        <div className="signature-headline">{text.ResultsTab.labels.companyData}</div>
        <div className="input-section">
          <Form.Group as={Col} controlId="resultTabName">
            <Form.Label>{text.ResultsTab.labels.name}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              defaultValue={defaultValue.nameSignerEmployer}
              onChange={(e) => {
                handleChange('nameSignerEmployer', e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="resultTabEmail">
            <Form.Label>{text.ResultsTab.labels.email}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              defaultValue={defaultValue.emailSignerEmployer}
              onChange={(e) => {
                handleChange('emailSignerEmployer', e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="emailCC">
            <Form.Label>{text.ResultsTab.labels.emailCC}</Form.Label>
            <Form.Control
              type="text"
              name="emailCC"
              defaultValue={inspection.emailCC}
              onChange={(e) => {
                handleChange('emailCC', e.target.value);
              }}
            />
          </Form.Group>
        </div>

        <Row className="signature-section">
          {!isCompanySigned ? (
            <p className="signature-sentence">{text.ResultsTab.labels.isNotSigned}</p>
          ) : (
            <p className="signature-sentence signature-signed">
              {text.ResultsTab.labels.isSigned}
            </p>
          )}
          <SignatureCanvas
            canvasProps={{ className: 'sigCanvas' }}
            ref={sigCanvas.company}
          />

          {isCompanySigned ? (
            <img
              src={companySignature}
              width="120"
              height="100"
              alt="signature"
              className="image-signature"
            />
          ) : null}

          <div className="buttons">
            <button className="btn-transparent" onClick={() => clearPad('company')}>
              {text.general.clear}
            </button>
            <Button disabled={disabledCompanyButton} onClick={() => savePad('company')}>
              {text.general.saveSignature}
            </Button>
          </div>
        </Row>
      </section>
    </div>
  );
};

export default ResultsTab;
