import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import text from '../../../text';

const TubeDeleteModal = (props) => {
  function onAccept() {
    props.onAccept();
    props.onHide();
  }
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {text.TubeDeleteModal.headline}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text.TubeDeleteModal.confirmation}</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn" onClick={props.onHide}>
          {text.general.cancel}
        </button>
        <button className="btn btn-primary" onClick={onAccept}>
          {text.TubeDeleteModal.delete}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

TubeDeleteModal.propTypes = {
  onHide: PropTypes.func,
  onAccept: PropTypes.func,
};

export default TubeDeleteModal;
